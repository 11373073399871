import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import { number, string } from "prop-types";
import React, { useState } from "react";

function shortDate(date, language = "en") {
  return new Date(date).toLocaleDateString(language, {
    year: "numeric",
    month: "short",
  });
}

function diffMonths(start = 0, end) {
  return Math.round(((end || Date.now()) - start) / 2.628e9);
}

export default function FormationCard({
  school,
  location,
  name,
  description,
  startedAt,
  endedAt,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const months = diffMonths(startedAt, endedAt);

  const subheader = (
    <>
      <Typography align="left">
        {months > 0
          ? `${shortDate(startedAt)} - ${
              endedAt ? shortDate(endedAt) : "Aujourd'hui"
            }, ${months} ${months > 1 ? "months" : "month"}`
          : shortDate(endedAt)}
      </Typography>
      <Typography align="left">
        {[school, location].filter((el) => el?.trim().length).join(", ")}
      </Typography>
    </>
  );

  return (
    <Card>
      <CardHeader
        title={name}
        subheader={subheader}
        titleTypographyProps={{ align: "left", variant: "h6" }}
        subheaderTypographyProps={{ align: "left", variant: "subtitle2" }}
        sx={{ pb: 0 }}
      />

      {description && (
        <>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={expanded ? "Voir moins" : "Voir plus"}
              endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
              size="small"
            >
              {expanded ? "Voir moins" : "Voir plus"}
            </Button>
          </CardActions>
          <Collapse in={expanded} timeout="auto">
            <CardContent align="left" sx={{ pt: 0 }}>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
}

export const FormationProps = {
  school: string,
  name: string,
  startedAt: number,
  endedAt: number,
  location: string,
};

FormationCard.propTypes = {
  ...FormationProps,
};

FormationCard.defaultProps = {
  school: null,
  name: null,
  startedAt: null,
  endedAt: null,
  location: null,
};
