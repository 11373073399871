import { Edit } from "react-admin";

import RolesForm from "./RoleForm";

export default function RoleEdit(props) {
  return (
    <Edit {...props}>
      <RolesForm />
    </Edit>
  );
}
