import { Grid } from "@mui/material";
import { useGetList } from "react-admin";

import Column from "./Column";

export default function Board({
  resource,
  ids,
  basePath,
  columnReference,
  CardComponent,
}) {
  const {
    data: columns,
    isLoading,
    error,
  } = useGetList(columnReference, {
    filter: {
      id: ids,
    },
  });

  if (isLoading || error) return null;

  return (
    <Grid container spacing={2}>
      {ids.map((id) => (
        <Grid item key={id} xs={12} md={Math.trunc(12 / ids.length)}>
          <Column
            resource={resource}
            basePath={basePath}
            value={id}
            source="status"
            name={columns.find((column) => column.id === id)?.name}
            CardComponent={CardComponent}
          />
        </Grid>
      ))}
    </Grid>
  );
}
