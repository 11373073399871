import { ExpandMore } from "@mui/icons-material";
import {
  alpha,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { cloneElement, useCallback, useState } from "react";
import { useGetList, useRefresh, useUpdate } from "react-admin";

import useHover from "../../utils/useHover";
import Draggable from "../Draggable";

function Card({ children, onDragStart }) {
  const [ref, hover] = useHover();
  return (
    <Draggable onDragStart={onDragStart}>
      <div ref={ref}>
        {cloneElement(children, { elevation: hover ? 1 : 0 })}
      </div>
    </Draggable>
  );
}

export default function Column({
  name,
  value,
  source,
  resource,
  CardComponent,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [hover, setHover] = useState(false);
  const [expanded, setExpanded] = useState(!isSmallScreen);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const refresh = useRefresh();
  const { data, isLoading, error } = useGetList(resource, {
    sort: { field: "updatedAt", order: "DESC" },
    filter: { [source]: value },
  });
  const [update] = useUpdate();

  const onCardDragOver = useCallback((event) => {
    setHover(true);
    event.preventDefault();
  }, []);

  const onCardDragStart = useCallback(
    (id, record) => (event) => {
      setHover(false);
      event.dataTransfer.setData("id", id);
      event.dataTransfer.setData("record", JSON.stringify(record));
    },
    [],
  );

  const onCardDragEnter = useCallback(() => {
    setHover(true);
  }, []);

  const onCardDragLeave = useCallback(() => {
    setHover(false);
  }, []);

  const onCardDrop = useCallback(
    (event) => {
      setHover(false);
      const id = event.dataTransfer.getData("id");
      const record = JSON.parse(event.dataTransfer.getData("record"));
      if (value !== record[source]) {
        update(
          resource,
          {
            id,
            data: { ...record, [source]: value },
            previousData: record,
          },

          {
            onSuccess: refresh,
          },
        );
      }
    },
    [refresh, resource, source, update, value],
  );

  return (
    <MuiCard
      elevation={hover ? 1 : 0}
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: (theme) =>
          isSmallScreen
            ? alpha(theme.palette.background.paper, 0.3)
            : "transparent",
        cursor: "grab",
      }}
      onDragEnter={onCardDragEnter}
      onDragLeave={onCardDragLeave}
      onDragOver={onCardDragOver}
      onDrop={onCardDrop}
    >
      <CardHeader
        title={name}
        titleTypographyProps={{ color: "textSecondary" }}
        subheader={data?.length > 0 ? `${data?.length} profil(s)` : null}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
      {isSmallScreen && (
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={toggleExpand}>
            <ExpandMore
              aria-expanded={expanded}
              aria-label={expanded ? "voir moins" : "voir plus"}
              sx={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                ml: "auto",
              }}
            />
          </IconButton>
        </CardActions>
      )}
      <CardContent>
        {!isLoading && !error && (expanded || !isSmallScreen) && (
          <Grid container spacing={4} direction="column">
            {data.map((record) => (
              <Grid item key={record.id}>
                <Card onDragStart={onCardDragStart(record.id, record)}>
                  <CardComponent record={record} resource={resource} />
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </MuiCard>
  );
}
