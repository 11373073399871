import { Grid, Stack } from "@mui/material";
import {
  ArrayField,
  ChipField,
  Datagrid,
  EmailField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  SimpleList,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";

import CommentsField from "../../components/CommentsField";
import ContractTypesField from "../../components/ContractTypeField";
import CreateResumeButton from "../../components/CreateResumeButton";
import DailyRateField from "../../components/DailyRateField";
import ExperiencesField from "../../components/ExperiencesField";
import FormationsField from "../../components/FormationsField";
import FullNameField from "../../components/FullNameField";
import LinkToResumeField from "../../components/LinkToResumeField";
import MainSkillsField from "../../components/MainSkillsField";
import PhoneNumberField from "../../components/PhoneNumberField";
import ProfessionalNetworksField from "../../components/ProfessionalNetworksField";
import TitleField from "../../components/TitleField";
import TotalXPField from "../../components/TotalXPField";
import UpdateStatusButtons from "../../components/UpdateStatusButtons";
import shortDate from "../../utils/shortDate";

export default function ProfileShow(props) {
  return (
    <Show
      {...props}
      title={<FullNameField component="span" variant="h6" />}
      component="div"
    >
      <Grid container spacing={4}>
        <Grid item>
          <SimpleShowLayout>
            <Stack spacing={4}>
              <Stack spacing={1} alignItems="flex-start">
                <TitleField variant="h2" />
              </Stack>
              <Stack spacing={1} alignItems="flex-start" direction="row">
                <ReferenceField
                  source="status"
                  reference="statuses"
                  link={false}
                >
                  <ChipField source="name" />
                </ReferenceField>
                <UpdateStatusButtons />
              </Stack>
              <Stack spacing={1} alignItems="flex-start">
                <ProfessionalNetworksField source="professionalNetworks" />
                <PhoneNumberField source="phoneNumber" />
                <EmailField source="emailAdress" />
              </Stack>
              <Stack spacing={1} alignItems="flex-start">
                <ReferenceField
                  reference="seniorityLevels"
                  source="seniority"
                  link={false}
                >
                  <ChipField source="name" size="small" />
                </ReferenceField>
                <ReferenceArrayField source="skills" reference="skills">
                  <SingleFieldList
                    component={Stack}
                    direction="row"
                    alignItems="center"
                  >
                    <ChipField source="name" size="small" />
                  </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="badges" reference="badges">
                  <SingleFieldList
                    component={Stack}
                    direction="row"
                    alignItems="center"
                  >
                    <ChipField source="name" size="small" />
                  </SingleFieldList>
                </ReferenceArrayField>
                <TotalXPField source="experiences" />
              </Stack>
              <Stack spacing={1} alignItems="flex-start">
                <ContractTypesField
                  label="Types de contrat"
                  source="contractTypes"
                />
                <DailyRateField label="TJM min." source="dailyRate" />
              </Stack>
            </Stack>
          </SimpleShowLayout>
        </Grid>
        <Grid item xs>
          <TabbedShowLayout>
            <Tab label="Commentaires">
              <CommentsField source="comments" label="" />
            </Tab>
            <Tab label="Introduction">
              <RichTextField label="" source="intro" fullWidth />
            </Tab>
            <Tab label="Expériences">
              <MainSkillsField />
              <ExperiencesField label="" source="experiences" />
            </Tab>
            <Tab label="Formations">
              <FormationsField label="" source="formations" />
            </Tab>
            <Tab label="Suivi du TJM">
              <ArrayField label="Expériences précédentes" source="experiences">
                <Datagrid>
                  <TextField source="title" />
                  <TextField source="company" />
                  <NumberField source="dailyRate" label="Daily rate (€)" />
                </Datagrid>
              </ArrayField>
            </Tab>
            <Tab label="Fiches de compétences">
              <CreateResumeButton />
              <ReferenceManyField target="profile" reference="resumes" label="">
                <SimpleList
                  linkType={false}
                  primaryText={(record) => record.title}
                  secondaryText={(record) => shortDate(record.updatedAt)}
                  tertiaryText={(_record, id) => (
                    <LinkToResumeField
                      url={`https://www.ctrlup.io/resume/${id}`}
                    />
                  )}
                />
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
}
