import {
  BooleanField,
  BooleanInput,
  ChipField,
  CloneButton,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  UrlField,
} from "react-admin";

function JobFilter(props) {
  return (
    <Filter {...props}>
      <BooleanInput label="Actives" source="active" alwaysOn />
    </Filter>
  );
}

export default function JobList(props) {
  return (
    <List
      {...props}
      sort={{ field: "startingDate", order: "ASC" }}
      filters={<JobFilter />}
      filterDefaultValues={{
        active: true,
      }}
    >
      <Datagrid>
        <TextField label="Titre" source="title" />
        <ReferenceArrayField
          label="Séniorités"
          source="seniorities"
          reference="seniorityLevels"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField label="Avec responsabilité" source="withResponsability" />
        <ReferenceArrayField
          label="Stack technique"
          source="mainSkills"
          reference="skills"
          sort={{ field: "name", order: "ASC" }}
          link={false}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label="Localisation" source="location" />
        <BooleanField label="Télé-travail" source="remote" />
        <DateField label="Date de démarrage" source="startingDate" />
        <UrlField label="Lien de candidature" source="applicationUrl" />
        <EditButton />
        <CloneButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
