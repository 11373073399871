import React, { useMemo } from "react";
import { useRecordContext } from "react-admin";

import pluralize from "../utils/pluralize";
import unionNumberRanges from "../utils/unionNumberRanges";
import MetricField from "./MetricField";

const MILLISECONDS_PER_YEAR = 3.154e10;

export default function TotalXPField(props) {
  const record = useRecordContext();
  const { value, unit, helperText } = useMemo(() => {
    const ranges = unionNumberRanges(
      (record?.experiences || []).map((experience) => [
        experience.startedAt,
        experience.endedAt || Date.now(),
      ]),
    );

    const milliseconds = ranges.reduce(
      (acc, range) => acc + range[1] - range[0],
      0,
    );
    const years = milliseconds / MILLISECONDS_PER_YEAR;
    const value = [Math.trunc(years), years % 1 > 0.5 ? "+" : ""].join(" ");
    const unit = `${pluralize("année", years)} d'xp`;

    const startYear = ranges?.[0]?.[0];
    const helperText = startYear
      ? `depuis ${new Date(startYear).getUTCFullYear()}`
      : null;

    return { value, unit, helperText };
  }, [record?.experiences]);
  if (!record) return null;
  return (
    <MetricField value={value} unit={unit} helperText={helperText} {...props} />
  );
}
