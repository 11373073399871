import { onAuthStateChanged } from "firebase/auth";

export default async function getUserLogin(auth) {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;
    if (currentUser) return resolve(currentUser);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
}
