import { PeopleOutline } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import UserEdit from "./UserEdit";
import UserList from "./UserList";

const users = {
  edit: UserEdit,
  list: UserList,
  icon: PeopleOutline,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Utilisateurs" },
};

export default users;
