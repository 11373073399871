import {
  AutocompleteArrayInput,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
} from "react-admin";

import FullNameField from "../../components/FullNameField";
import {
  GENERAL_REVIEW,
  REFINEMENT,
  TECHNICAL_REVIEW,
} from "../recruitmentStatuses/statuses";

function RecruitmentFilter(props) {
  return (
    <Filter {...props} sx={{ mt: 2 }}>
      <ReferenceArrayInput
        source="status"
        reference="recruitmentStatuses"
        alwaysOn
      >
        <AutocompleteArrayInput label="Statuts" optionText="name" />
      </ReferenceArrayInput>
    </Filter>
  );
}

export default function RecruitmentList(props) {
  return (
    <List
      {...props}
      filters={<RecruitmentFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      filterDefaultValues={{
        status: [GENERAL_REVIEW, TECHNICAL_REVIEW, REFINEMENT],
      }}
    >
      <Datagrid>
        <ReferenceField label="Profil" source="profile" reference="profiles">
          <FullNameField />
        </ReferenceField>
        <ReferenceField
          label="Statut"
          reference="recruitmentStatuses"
          source="status"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
