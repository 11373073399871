import { TheaterComedy } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import RoleCreate from "./RoleCreate";
import RoleEdit from "./RoleEdit";
import RoleList from "./RoleList";

const roles = {
  create: RoleCreate,
  edit: RoleEdit,
  list: RoleList,
  icon: TheaterComedy,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Rôles" },
};

export default roles;
