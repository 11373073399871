import { Label } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import BadgeCreate from "./BadgeCreate";
import BadgeEdit from "./BadgeEdit";
import BadgeList from "./BadgeList";

const matchStatuses = {
  create: BadgeCreate,
  edit: BadgeEdit,
  list: BadgeList,
  icon: Label,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
};

export default matchStatuses;
