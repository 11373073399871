import { Divider } from "@mui/material";
import { UserMenu as RaUserMenu } from "react-admin";

import AccountMenu from "./AccountMenu";
import LogoutMenu from "./LogoutMenu";

const UserMenu = (props) => (
  <RaUserMenu {...props}>
    <AccountMenu />
    <Divider />
    <LogoutMenu />
  </RaUserMenu>
);

export default UserMenu;
