import { Button, useResourceContext } from "react-admin";
import { Link as RouterLink } from "react-router-dom";

function CancelButton(props) {
  const resource = useResourceContext(props);
  if (!resource) return null;
  return (
    <Button
      component={RouterLink}
      to={`/${resource}`}
      label="Annuler"
      color="error"
      size="medium"
    />
  );
}

export default CancelButton;
