import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
} from "react-admin";

export default function BadgeList(props) {
  return (
    <List {...props} sort={{ field: "name", order: "ASC" }}>
      <Datagrid>
        <TextField label="Nom" source="name" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
