import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  TextField,
} from "react-admin";

export default function SeniorityLevelList(props) {
  return (
    <List {...props} sort={{ field: "experience.min", order: "ASC" }}>
      <Datagrid>
        <TextField label="Nom" source="name" />
        <NumberField label="Expérience min." source="experience.min" />
        <NumberField label="Expérience max." source="experience.max" />
        <NumberField label="Salaire annuel brut (K€)" source="salary.base" />
        <NumberField
          label="Bonus avec responsabilités (K€)"
          source="salary.bonus"
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
