import { EmojiPeople } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import RecruitmentStatusCreate from "./RecruitmentStatusCreate";
import RecruitmentStatusEdit from "./RecruitmentStatusEdit";
import RecruitmentStatusList from "./RecruitmentStatusList";

const recruitmentStatuses = {
  create: RecruitmentStatusCreate,
  edit: RecruitmentStatusEdit,
  list: RecruitmentStatusList,
  icon: EmojiPeople,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Statuts de recrutement" },
};

export default recruitmentStatuses;
