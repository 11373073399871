import { ViewKanban, ViewList } from "@mui/icons-material";
import { ButtonGroup, Stack, Toolbar } from "@mui/material";
import React, { useState } from "react";

import ViewButton from "../../components/ViewButton";
import MatchBoard from "./MatchBoard";
import MatchList from "./MatchList";

const matrix = "matrix";
const list = "list";
const views = {
  [matrix]: <MatchBoard />,
  [list]: <MatchList />,
};

export default function MatchAll() {
  const [view, setView] = useState(matrix);
  const onChangeView = (name) => () => {
    setView(name);
  };
  return (
    <Stack spacing={2}>
      <Toolbar>
        <ButtonGroup>
          <ViewButton
            onClick={onChangeView(matrix)}
            startIcon={<ViewKanban />}
            isActive={view === matrix}
          >
            Vue matrice
          </ViewButton>
          <ViewButton
            onClick={onChangeView(list)}
            startIcon={<ViewList />}
            isActive={view === list}
          >
            Vue liste
          </ViewButton>
        </ButtonGroup>
      </Toolbar>
      {views[view]}
    </Stack>
  );
}
