import { useCreate } from "react-admin";

function useRegister(identity) {
  const [create, { isLoading, error }] = useCreate();
  if (!identity) return { isLoading: false, error: true };
  const data = {
    fullName: identity.fullName,
    email: identity.email,
    avatar: identity.avatar,
    identityId: identity.id,
  };
  const onSuccess = window.location.reload;
  const register = async () =>
    await create(
      "users",
      {
        data,
      },
      {
        onSuccess,
      },
    );
  return { register, isLoading, error };
}

export default useRegister;
