import { Edit } from "react-admin";

import SkillsForm from "./SkillForm";

export default function SkillEdit(props) {
  return (
    <Edit {...props}>
      <SkillsForm />
    </Edit>
  );
}
