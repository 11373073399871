import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import { arrayOf, number, string } from "prop-types";
import React, { useState } from "react";

import SkillsField from "../SkillsField";
import duration from "./duration";

function shortDate(date, language = "en") {
  return new Date(date).toLocaleDateString(language, {
    year: "numeric",
    month: "short",
  });
}

export default function ExperienceCard({
  company,
  location,
  title,
  description,
  skills,
  startedAt,
  endedAt,
  dailyRate,
  ...props
}) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const dates = `${shortDate(startedAt)} - ${
    endedAt ? shortDate(endedAt) : "Aujourd'hui"
  }`;

  const subheader = (
    <>
      <Typography align="left">
        {dates}, {duration(startedAt, endedAt)}
      </Typography>
      <Typography align="left">
        {[company, location].filter(Boolean).join(", ")}
      </Typography>
    </>
  );

  return (
    <Card elevation={0}>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: "h3" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
      {skills && (
        <CardContent>
          <SkillsField {...props} source="skills" record={{ skills }} />
        </CardContent>
      )}
      {description && (
        <>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={expanded ? "Voir moins" : "Voir plus"}
              endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
              size="small"
            >
              {expanded ? "Voir moins" : "Voir plus"}
            </Button>
          </CardActions>
          <Collapse in={expanded} timeout="auto">
            <CardContent align="left" sx={{ pt: 0 }}>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
}

export const ExperienceProps = {
  company: string,
  title: string,
  startedAt: number,
  endedAt: number,
  description: string,
  location: string,
  dailyRate: number,
  skills: arrayOf(string),
};

ExperienceCard.propTypes = {
  ...ExperienceProps,
};

ExperienceCard.defaultProps = {
  company: null,
  title: null,
  startedAt: null,
  endedAt: null,
  location: null,
  description: null,
  skills: [],
};
