function getFirebaseEnvVar(name = "") {
  const snakeCaseName = `REACT_APP_${name
    .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
    .toUpperCase()}`;
  const envVar = process.env[snakeCaseName];
  if (!envVar) {
    throw new Error(`${snakeCaseName} is missing in environment variables`);
  }
  return envVar;
}

function buildFirebaseConfig(envVars = []) {
  return Object.fromEntries(
    envVars.map((name) => [name, getFirebaseEnvVar(name)]),
  );
}

const config = buildFirebaseConfig([
  "apiKey",
  "authDomain",
  "projectId",
  "messagingSenderId",
  "appId",
]);

export default config;
