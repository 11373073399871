import { Stack } from "@mui/material";
import { DeleteButton, SaveButton, Toolbar } from "react-admin";

import CancelButton from "./CancelButton";

export default function FormToolbar(props) {
  return (
    <Toolbar
      {...props}
      sx={{
        display: "flex",
      }}
    >
      <SaveButton />
      <Stack flexGrow={1} spacing={2} direction="row" justifyContent="flex-end">
        <CancelButton />
        <DeleteButton />
      </Stack>
    </Toolbar>
  );
}
