import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

export default function TitleField(props) {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Box>
      <Typography {...props}>
        {record.firstname} {record.lastname}
      </Typography>
      <Typography>{record.jobtitle}</Typography>
    </Box>
  );
}
