import { getApp, getApps, initializeApp } from "firebase/app";

import log from "../utils/log";
import config from "./config";

const initialize = () => {
  const apps = getApps();
  if (apps.length === 0) {
    log(`🔥 Firebase app initialized!`);
    const app = initializeApp(config);
    return app;
  }
  const app = getApp();
  return app;
};

export default initialize;
