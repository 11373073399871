import { HowToReg, Mail } from "@mui/icons-material";
import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { useUserMenu } from "react-admin";

import useCurrentUser from "../utils/useCurrentUser";
import useRegister from "../utils/useRegister";

const AccountMenu = forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { user, identity } = useCurrentUser();
  const { register } = useRegister(identity);

  if (!identity?.id)
    return (
      <Box px={2} py={0.75}>
        <Typography>Aucun utilisateur connecté.</Typography>
      </Box>
    );

  if (!user) {
    return (
      <>
        <Box px={2} py={0.75}>
          <Typography>
            Ton compte n'existe pas.
            <br />
          </Typography>
        </Box>
        <MenuItem ref={ref} {...props} onClick={register}>
          <ListItemIcon>
            <HowToReg />
          </ListItemIcon>
          <ListItemText>S'enregister</ListItemText>
        </MenuItem>
      </>
    );
  }

  if (!user.role) {
    const onContact = () => {
      window.open("mailto:gcp-organization-admins@ctrlup.io", "_blank");
      onClose();
    };
    return (
      <>
        <Box px={2} py={0.75}>
          <Typography>
            Tu es enregistré mais n'es associé à aucun rôle. Un adminstrateur
            doit mettre à jour ton compte.
            <br />
          </Typography>
        </Box>
        <MenuItem ref={ref} {...props} onClick={onContact}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText>Contacter un administrateur</ListItemText>
        </MenuItem>
      </>
    );
  }
  return (
    <Box px={2} py={0.75}>
      <Typography>
        Tu es enregistré avec le rôle{" "}
        <Chip label={user.role} component="span" size="small" />.
      </Typography>
    </Box>
  );
});

export default AccountMenu;
