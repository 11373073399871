import { Stack, Typography } from "@mui/material";

export default function MetricField({ value, unit, helperText }) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography fontWeight="bold" variant="h3" component="p">
        {value}
      </Typography>
      <Stack>
        <Typography color="textPrimary" variant="body2">
          {unit}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {helperText}
        </Typography>
      </Stack>
    </Stack>
  );
}
