import { Loyalty } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import SeniorityLevelCreate from "./SeniorityLevelCreate";
import SeniorityLevelEdit from "./SeniorityLevelEdit";
import SeniorityLevelList from "./SeniorityLevelList";

const seniorityLevels = {
  create: SeniorityLevelCreate,
  edit: SeniorityLevelEdit,
  list: SeniorityLevelList,
  icon: Loyalty,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Niveaux de séniorité" },
};

export default seniorityLevels;
