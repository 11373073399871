import { Chip, Stack } from "@mui/material";
import { useReferenceArrayFieldController } from "react-admin";

export default function SkillsField({
  record = {},
  basePath,
  resource,
  source,
}) {
  const { data, isLoading } = useReferenceArrayFieldController({
    basePath,
    record,
    reference: "skills",
    resource,
    source,
  });
  if (isLoading) return null;
  const skills = Object.values(data);
  return (
    <Stack component="span" direction="row" spacing={1}>
      {skills.map(({ id, name }) => (
        <Chip key={id} label={name} size="small" />
      ))}
    </Stack>
  );
}

SkillsField.defaultProps = { addLabel: true };
