export default function isStatusOk(status) {
  if (status >= 200 && status < 300) {
    return true;
  }
  switch (status) {
    case 401:
    case 403:
      return false;
    case 400:
    case 404:
    case 409:
    case 429:
    case 499:
    case 500:
    case 501:
    case 503:
    case 504:
    default:
      return true;
  }
}
