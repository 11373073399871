import { Card, CardContent, Typography } from "@mui/material";
import { number, string } from "prop-types";
import { useGetOne } from "react-admin";

function shortDate(date, language = "en") {
  return new Date(date).toLocaleDateString(language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export default function CommentCard({ content, createdAt, author }) {
  const { data, isLoading, error } = useGetOne(
    "users",
    { id: author },
    { enabled: Boolean(author) },
  );
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: content }}
          whiteSpace="pre-line"
        />
        <Typography variant="caption">
          {[!isLoading && !error && data?.fullName, shortDate(createdAt)]
            .filter(Boolean)
            .join(" · ")}
        </Typography>
      </CardContent>
    </Card>
  );
}

export const CommentProps = {
  content: string,
  createdAt: number,
};

CommentCard.propTypes = {
  ...CommentProps,
};

CommentCard.defaultProps = {
  content: null,
  createdAt: null,
};
