import { Edit } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import RecruitmentForm from "./RecruitmentForm";

export default function RecruitmentEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <RecruitmentForm toolbar={<FormToolbar />} />
    </Edit>
  );
}
