import { AssignmentInd } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import ResumeCreate from "./ResumeCreate";
import ResumeEdit from "./ResumeEdit";
import ResumeList from "./ResumeList";

const resumes = {
  create: ResumeCreate,
  edit: ResumeEdit,
  list: ResumeList,
  icon: AssignmentInd,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Fiches de compétences" },
};

export default resumes;
