import { FindReplace } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import MatchAll from "./MatchAll";
import MatchCreate from "./MatchCreate";
import MatchEdit from "./MatchEdit";

const matches = {
  create: MatchCreate,
  edit: MatchEdit,
  list: MatchAll,
  icon: FindReplace,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Propositions" },
};

export default matches;
