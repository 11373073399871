import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
} from "react-admin";

import types from "./types";

function SkillFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
    </Filter>
  );
}

export default function SkillList(props) {
  return (
    <List
      {...props}
      sort={{ field: "updatedAt", order: "ASC" }}
      filters={<SkillFilter />}
    >
      <Datagrid>
        <TextField label="Nom" source="name" />
        <FunctionField
          source="type"
          render={(record) =>
            types.find((type) => type.id === record.type)?.name
          }
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
