import { GitHub, LinkedIn } from "@mui/icons-material";
import { Grid, Link } from "@mui/material";
import { useRecordContext } from "react-admin";

import GitLab from "../Gitlab";

const networks = [
  { label: "LinkedIn", source: "linkedInUrl", Icon: LinkedIn },
  { label: "GitLab", source: "gitLabUrl", Icon: GitLab },
  { label: "GitHub", source: "gitHubUrl", Icon: GitHub },
];

export default function ProfessionalNetworksField() {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Grid container spacing={1} component="nav" wrap="nowrap">
      {networks
        .filter((network) => Boolean(record[network.source]))
        .map((network) => (
          <Grid
            item
            href={record[network.source]}
            key={network.label}
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <network.Icon titleAccess={network.label} fontSize="small" />
          </Grid>
        ))}
    </Grid>
  );
}

ProfessionalNetworksField.defaultProps = {
  label: "Réseaux sociaux",
};
