import { ArchiveOutlined } from "@mui/icons-material";
import {
  AutocompleteArrayInput,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";

import BadgeField from "../../components/BadgeField";
import status, { defaultStatus } from "./status";

const NeedFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput source="status" choices={status} alwaysOn />
    <ReferenceArrayInput
      source="skills"
      reference="skills"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <AutocompleteArrayInput label="Compétences" optionText="name" fullWidth />
    </ReferenceArrayInput>
  </Filter>
);

const ArchiveManyButton = ({ resource, selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { isLoading, error }] = useUpdateMany(
    resource,
    {
      ids: selectedIds,
      data: {
        status: "Archived",
      },
    },
    {
      onSuccess: () => {
        unselectAll(resource);
        refresh();
        notify("Besoin archivé");
      },
    },
  );
  if (isLoading || error) return null;
  return (
    <Button
      label="Archiver"
      startIcon={<ArchiveOutlined />}
      onClick={updateMany}
      color="error"
    />
  );
};

export default function NeedList(props) {
  return (
    <List
      {...props}
      sort={{ field: "dueDate", order: "desc" }}
      filters={<NeedFilter />}
      filterDefaultValues={{
        status: defaultStatus,
      }}
      bulkActionButtons={<ArchiveManyButton />}
    >
      <Datagrid>
        <TextField source="client" />
        <TextField label="Titre" source="name" />
        <ReferenceArrayField
          label="Séniorité"
          source="seniorities"
          reference="seniorityLevels"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField label="Avec responsabilité" source="withResponsability" />
        <NumberField label="TJM (€)" source="dailyRate" />
        <ReferenceArrayField
          label="Compétences"
          reference="skills"
          source="skills"
        >
          <SingleFieldList linkType={false}>
            <BadgeField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Date d'échéance" source="dueDate" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
