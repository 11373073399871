import { Edit } from "react-admin";

import JobForm from "./JobForm";

export default function JobEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <JobForm />
    </Edit>
  );
}
