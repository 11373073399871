import { Create } from "react-admin";

import BadgeForm from "./BadgeForm";

export default function BadgeCreate(props) {
  return (
    <Create {...props} redirect="list">
      <BadgeForm />
    </Create>
  );
}
