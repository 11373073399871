import { Button, ButtonGroup } from "@mui/material";
import {
  useGetMany,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";

export default function UpdateStatusButtons(props) {
  const record = useRecordContext();
  const status = useGetOne(
    "statuses",
    { id: record?.status },
    { enabled: Boolean(record) },
  );
  const statuses = useGetMany(
    "statuses",
    { ids: status.data?.next },
    {
      enabled: status.data?.next?.length > 0,
    },
  );
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const next = (statuses?.data || []).filter(Boolean);
  if (
    status.isLoading ||
    status.error ||
    statuses.isLoading ||
    statuses.error ||
    next.length === 0
  )
    return null;

  return (
    <ButtonGroup color="primary" size="small" {...props}>
      {next.map((status) => (
        <Button
          key={status.id}
          disabled={isLoading}
          onClick={() =>
            update(
              "profiles",
              {
                id: record.id,
                data: { status: status.id },
                previousData: record,
              },
              {
                onSuccess: () => {
                  refresh();
                  notify("Profil mis à jour");
                },
              },
            )
          }
        >
          {status.name}
        </Button>
      ))}
    </ButtonGroup>
  );
}
