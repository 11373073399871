import get from "lodash/get";
import React from "react";
import { useRecordContext } from "react-admin";

import MetricField from "./MetricField";

export default function DailyRateField({ label, source }) {
  const record = useRecordContext();
  const value = get(record, source);
  if (!value) return null;
  return (
    <MetricField
      value={new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value)}
      unit="€"
      helperText={label}
    />
  );
}
