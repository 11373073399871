import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useRecordContext } from "react-admin";

import compareExperiences from "../../utils/compareExperiences";
import ExperienceCard from "../ExperienceCard";

export default function ExperiencesField({ source, ...props }) {
  const record = useRecordContext();
  const experiences = useMemo(
    () => (record[source] || []).sort(compareExperiences),
    [record, source],
  );
  if (experiences.length === 0)
    return <Typography variant="caption">Pas d'expérience.</Typography>;
  return (
    <Grid container spacing={4} direction="column">
      {experiences.map((experienceProps) => (
        <Grid
          item
          key={`${experienceProps.startedAt}-${experienceProps.endedAt}`}
        >
          <ExperienceCard {...props} {...experienceProps} />
        </Grid>
      ))}
    </Grid>
  );
}
