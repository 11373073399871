import {
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";

import isAuthorized from "../../utils/isAuthorized";
import roleChoices from "../../utils/roleChoices";
import roles, { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";

export default function UserForm(props) {
  const { isLoading, permissions } = usePermissions();
  const isAdmin = isAuthorized([ROLE_SUPER_ADMIN, ROLE_ADMIN], permissions);
  const choices = roleChoices.filter(({ restrictedTo = roles }) =>
    isAuthorized(restrictedTo, permissions),
  );
  return (
    <SimpleForm {...props}>
      <TextInput label="Nom et prénom" source="fullName" disabled fullWidth />
      <TextInput
        label="Adresse email associée"
        source="email"
        disabled
        fullWidth
      />
      {!isLoading && (
        <FormDataConsumer>
          {({ formData }) => (
            <SelectInput
              label="Rôle"
              source="role"
              choices={choices}
              disabled={!isAdmin || formData.role === ROLE_SUPER_ADMIN}
              fullWidth
            />
          )}
        </FormDataConsumer>
      )}
    </SimpleForm>
  );
}
