import { Create } from "react-admin";

import SeniorityLevelForm from "./SeniorityLevelForm";

export default function SeniorityLevelCreate(props) {
  return (
    <Create {...props} redirect="list">
      <SeniorityLevelForm />
    </Create>
  );
}
