import { NumberInput, required, SimpleForm, TextInput } from "react-admin";

export default function SeniorityLevelForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput label="Nom" source="name" validate={[required()]} fullWidth />
      <NumberInput
        label="Expérience min."
        source="experience.min"
        validate={[required()]}
        fullWidth
      />
      <NumberInput label="Expérience max." source="experience.max" fullWidth />
      <NumberInput
        label="Salaire brut annuel (K€)"
        source="salary.base"
        fullWidth
      />
      <NumberInput
        label="Bonus avec responsabilités (K€)"
        source="salary.bonus"
        fullWidth
      />
    </SimpleForm>
  );
}
