import { Edit } from "react-admin";

import MatchStatusForm from "./MatchStatusForm";

export default function MatchStatusEdit(props) {
  return (
    <Edit {...props}>
      <MatchStatusForm />
    </Edit>
  );
}
