import { SimpleForm, TextInput } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import NextStatusInput from "../../components/NextStatusInput";

export default function MatchStatusForm(props) {
  return (
    <SimpleForm toolbar={<FormToolbar />} {...props}>
      <TextInput label="Nom" source="name" fullWidth />
      <NextStatusInput
        label="Prochains statuts"
        source="next"
        reference="matchStatuses"
        fullWidth
      />
    </SimpleForm>
  );
}
