import { Face2 } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import ProfileCreate from "./ProfileCreate";
import ProfileEdit from "./ProfileEdit";
import ProfileList from "./ProfileList";
import ProfileShow from "./ProfileShow";

const profiles = {
  create: ProfileCreate,
  edit: ProfileEdit,
  list: ProfileList,
  show: ProfileShow,
  icon: Face2,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Profils" },
};

export default profiles;
