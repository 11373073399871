import { CheckCircle, FileCopy } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { writeText } from "clipboard-polyfill/text";
import React, { useCallback, useState } from "react";
import { EditButton } from "react-admin";

export default function LinkToResumeField({ url }) {
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    writeText(url).then(() => {
      setCopied(true);
    });
  }, [url]);

  return (
    <Stack direction="row" spacing={2}>
      <Button
        color="primary"
        variant="contained"
        size="small"
        href={url}
        target="_blank"
      >
        Voir la fiche
      </Button>
      <Button
        variant={copied ? "outlined" : "contained"}
        size="small"
        startIcon={copied ? <CheckCircle color="primary" /> : <FileCopy />}
        onClick={onCopy}
      >
        {copied ? " Url copiée" : "Copier l'url"}
      </Button>
      <EditButton size="small" />
    </Stack>
  );
}
