const frenchMessages = {
  ra: {
    message: {
      clear_array_input: "Êtes-vous certains de tout supprimer ?",
    },
    action: {
      clear_array_input: "Tout supprimer",
    },
  },
};

export default frenchMessages;
