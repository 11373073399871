import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useInput } from "react-admin";

export default function DatePickerInput(props) {
  const { onChange, onBlur, ...rest } = props;

  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    parse: (value) => Date.parse(value) || null,
    ...props,
  });
  return (
    <DatePicker
      {...field}
      label={props.label}
      error={isTouched || isSubmitted}
      helperText={isTouched || isSubmitted ? error : ""}
      required={isRequired}
      renderInput={(params) => (
        <TextField {...params} sx={{ mt: 2, mb: 3 }} fullWidth />
      )}
      {...rest}
    />
  );
}
