import { Work } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_MEMBER, ROLE_SUPER_ADMIN } from "../../utils/roles";
import JobCreate from "./JobCreate";
import JobEdit from "./JobEdit";
import JobList from "./JobList";

const jobs = {
  create: JobCreate,
  edit: JobEdit,
  list: JobList,
  icon: Work,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MEMBER],
  options: { label: "Offres d'emploi" },
};

export default jobs;
