import { Resource } from "react-admin";
import { Navigate } from "react-router-dom";

import * as resources from "../resources";
import isAuthorized from "./isAuthorized";
import roles from "./roles";

export default function renderResources(permissions = []) {
  return (
    <>
      {Object.keys(resources).map((resource) => {
        const {
          name = resource,
          restrictedTo = roles,
          ...rest
        } = resources[resource];
        if (!isAuthorized(restrictedTo, permissions)) {
          return <Navigate key={name} to="/login" />;
        }
        return <Resource key={name} name={name} {...rest} />;
      })}
    </>
  );
}
