import React, { useCallback } from "react";
import { Create, useNotify, useRedirect, useUpdate } from "react-admin";

import { IN_RECRUITMENT } from "../profileStatuses/statuses";
import { GENERAL_REVIEW } from "../recruitmentStatuses/statuses";
import RecruitmentForm from "./RecruitmentForm";

export default function RecruitmentCreate(props) {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = useCallback(
    ({ data }) => {
      notify("ra.notification.created", { messageArgs: { smart_count: 1 } });
      update(
        "profiles",
        { id: data.profile, data: { status: IN_RECRUITMENT } },
        {
          onSuccess: () => {
            notify("ra.notification.updated", {
              messageArgs: { smart_count: 1 },
            });
            redirect("/");
          },
        },
      );
    },
    [notify, redirect, update],
  );

  return (
    <Create {...props} onSuccess={onSuccess} redirect="list">
      <RecruitmentForm defaultValues={{ status: GENERAL_REVIEW }} />
    </Create>
  );
}
