import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

export default function BadgeForm(props) {
  return (
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton redirect="list" />
        </Toolbar>
      }
      {...props}
    >
      <TextInput label="Nom" source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  );
}
