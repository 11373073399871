import { EmojiPeople } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import RecruitmentAll from "./RecruitmentAll";
import RecruitmentCreate from "./RecruitmentCreate";
import RecruitmentEdit from "./RecruitmentEdit";

const recruitments = {
  create: RecruitmentCreate,
  edit: RecruitmentEdit,
  list: RecruitmentAll,
  icon: EmojiPeople,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Recrutements" },
};

export default recruitments;
