import merge from "lodash/merge";
import { defaultTheme } from "react-admin";

const palette = {
  mode: "light",
  primary: {
    main: "#FB84C6",
    contrastText: "#FFFDF6",
  },
  secondary: {
    main: "#FB84C6",
    contrastText: "#FFFDF6",
  },
  common: {
    white: "#FFFDF6",
    black: "#131313",
  },
  background: {
    default: "#FFFDF6",
    paper: "#F0EEE7",
  },
  error: { main: "#FF8B92" },
  warning: { main: "#FFDCA8" },
  success: { main: "#98E4AD" },
};

const theme = merge(defaultTheme, {
  palette,
  shape: {
    borderRadius: 4,
  },
  sidebar: {
    width: 280,
  },
  typography: {
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: 700,
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 12,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
    },
    caption: {
      fontSize: 10,
    },
    overline: {
      fontSize: 10,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          whiteSpace: "wrap",
          "& .MuiListItemIcon-root": {
            minWidth: 40,
          },
        }),
      },
    },
    RaLogin: {
      styleOverrides: {
        root: {
          background: "#FFFDF6",
        },
      },
    },
    RaLogout: {
      styleOverrides: {
        icon: {
          background: "red",
        },
      },
    },
    RaSidebarToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: theme.spacing(1),
        }),
      },
    },
    RaReferenceArrayField: {
      styleOverrides: {
        root: {
          minHeight: 24,
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          "& .RaSimpleFormIterator-line": {
            paddingTop: 64,
            paddingBottom: 64,
          },
          "& .RaSimpleFormIterator-line:first-of-type": {
            paddingTop: 0,
          },
          "& .RaSimpleFormIterator-line:last-of-type": {
            paddingBottom: 0,
          },
        },
      },
    },
    RaRichTextInput: {
      styleOverrides: {
        root: {
          "& .RaRichTextInput-editorContent .ProseMirror": {
            backgroundColor: "transparent",
            "&:focus": {
              backgroundColor: "transparent",
              borderColor: palette.primary.main,
              outline: `1px solid ${palette.primary.main}`,
            },
          },
        },
      },
    },
  },
});

export default theme;
