import { differenceInMonths } from "date-fns";

import pluralize from "../../utils/pluralize";

export default function duration(startedAt, endedAt) {
  if (!startedAt) return null;
  const months = differenceInMonths(endedAt || new Date(), startedAt) || 1;
  if (months <= 11) {
    return `${months} mois`;
  }
  const years = Math.trunc(months / 12);
  const remainder = months - years * 12;
  return `${years} ${pluralize("année", years)}${
    remainder > 0 ? ` et ${remainder} mois` : ""
  }`;
}
