import { Avatar, Chip, Grid } from "@mui/material";
import {
  useRecordContext,
  useReferenceArrayFieldController,
} from "react-admin";

export default function MainSkillsField({ max = 5, resource, source }) {
  const record = useRecordContext();
  const skills = Object.values(
    (record?.experiences || [])
      .map((experience) => experience.skills || [])
      .flat()
      .reduce(
        (acc, id) => ({
          ...acc,
          [id]: { id, count: (acc?.[id]?.count || 0) + 1 },
        }),
        {},
      ),
  )
    .sort((a, b) => b.count - a.count)
    .slice(0, max);
  const { data, error, isLoading } = useReferenceArrayFieldController({
    record: {
      skills: skills.map((skill) => skill.id),
    },
    reference: "skills",
    resource,
    source: "skills",
  });
  if (isLoading || error || !record) return null;
  return (
    <Grid container spacing={1}>
      {skills.map((skill) => (
        <Grid item key={skill.id}>
          <Chip
            avatar={<Avatar>{skill.count}</Avatar>}
            label={data.find((record) => record.id === skill.id)?.name}
          />
        </Grid>
      ))}
    </Grid>
  );
}

MainSkillsField.defaultProps = { addLabel: true };
