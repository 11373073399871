import { ExpandLess, ExpandMore, Settings } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Menu as RaMenu, usePermissions, useSidebarState } from "react-admin";

import {
  badges,
  jobs,
  matches,
  matchStatuses,
  needs,
  profiles,
  profileStatuses,
  recruitments,
  recruitmentStatuses,
  resumes,
  roles,
  seniorityLevels,
  skills,
  users,
} from "../resources";
import isAuthorized from "../utils/isAuthorized";

const primaryLinks = [
  {
    to: "/profiles",
    label: "Profils",
    Icon: profiles.icon,
    restrictedTo: profiles.restrictedTo,
  },
  {
    to: "/resumes",
    label: "Fiche de compétences",
    Icon: resumes.icon,
    restrictedTo: resumes.restrictedTo,
  },
  {
    to: "/recruitments",
    label: "Recrutements",
    Icon: recruitments.icon,
    restrictedTo: recruitments.restrictedTo,
  },
  {
    to: "/matches",
    label: "Propositions",
    Icon: matches.icon,
    restrictedTo: matches.restrictedTo,
  },
  {
    to: "/needs",
    label: "Besoins",
    Icon: needs.icon,
    restrictedTo: needs.restrictedTo,
  },
  {
    to: "/jobs",
    label: "Offres d'emploi",
    Icon: jobs.icon,
    restrictedTo: jobs.restrictedTo,
  },
];

const secondaryLinks = [
  {
    to: "/users",
    label: "Utilisateurs",
    Icon: users.icon,
    restrictedTo: users.restrictedTo,
  },
  {
    to: "/statuses",
    label: "Statuts de profil",
    Icon: profileStatuses.icon,
    restrictedTo: profileStatuses.restrictedTo,
  },
  {
    to: "/recruitmentStatuses",
    label: "Statuts de recrutement",
    Icon: recruitmentStatuses.icon,
    restrictedTo: recruitmentStatuses.restrictedTo,
  },
  {
    to: "/matchStatuses",
    label: "Statuts de proposition",
    Icon: matchStatuses.icon,
    restrictedTo: matchStatuses.restrictedTo,
  },
  {
    to: "/roles",
    label: "Rôles",
    Icon: roles.icon,
    restrictedTo: roles.restrictedTo,
  },
  {
    to: "/skills",
    label: "Compétences",
    Icon: skills.icon,
    restrictedTo: skills.restrictedTo,
  },
  {
    to: "/badges",
    label: "Badges",
    Icon: badges.icon,
    restrictedTo: badges.restrictedTo,
  },
  {
    to: "/seniorityLevels",
    label: "Séniorité",
    Icon: seniorityLevels.icon,
    restrictedTo: seniorityLevels.restrictedTo,
  },
];

const Menu = () => {
  const [, setOpen] = useSidebarState();
  const { isLoading, permissions } = usePermissions();
  const [expanded, setExpanded] = useState(false);
  const onExpand = () => {
    setExpanded(!expanded);
  };
  if (isLoading) return null;
  const settings = secondaryLinks.filter((link) =>
    isAuthorized(link.restrictedTo, permissions),
  );
  const onClose = () => {
    setExpanded(false);
    setOpen(false);
  };
  return (
    <RaMenu>
      {primaryLinks
        .filter((link) => isAuthorized(link.restrictedTo, permissions))
        .map((link) => (
          <RaMenu.Item
            key={link.to}
            to={`${link.to}?filter=${JSON.stringify({})}`}
            primaryText={link.label}
            leftIcon={<link.Icon />}
            onClick={onClose}
          />
        ))}
      {settings.length > 0 && (
        <MenuItem onClick={onExpand}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <Typography flexGrow={1}>Paramètres</Typography>
          <IconButton size="small">
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </MenuItem>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {settings.map((link) => (
          <RaMenu.Item
            key={link.to}
            to={`${link.to}?filter=${JSON.stringify({})}`}
            primaryText={link.label}
            leftIcon={<link.Icon />}
            onClick={onClose}
          />
        ))}
      </Collapse>
    </RaMenu>
  );
};

export default Menu;
