import { Box, Button, Typography } from "@mui/material";
import { useGetIdentity } from "react-admin";
import { Navigate } from "react-router-dom";

function NotFound() {
  const { data: identity, isLoading, error } = useGetIdentity();
  const isNotAuth = !isLoading && !error && !identity;
  if (isNotAuth) {
    return <Navigate to="/login" />;
  }
  return (
    <Box mt={3}>
      <Typography fontWeight={700}>Oups !</Typography>
      <Typography>
        Désolé cette page n'existe pas ou tu n'es pas autorisé à y accéder.
        <br />
        Un administrateur peut y remédier.
      </Typography>
      <Button
        href="mailto:gcp-organization-admins@ctrlup.io"
        target="_blank"
        variant="contained"
        sx={{ mt: 1 }}
      >
        Contacter un administrateur
      </Button>
    </Box>
  );
}

export default NotFound;
