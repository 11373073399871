import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Dashboard() {
  return (
    <Box mt={3}>
      <Typography fontWeight={700}>
        Bienvenue sur Tatou !{" "}
        <Typography component="span" role="img" aria-label="waving hand">
          👋
        </Typography>
      </Typography>
      <Typography>
        Tu peux suivre les{" "}
        <Link to="/profiles" component={RouterLink}>
          profils
        </Link>
        , les{" "}
        <Link to="/recruitments" component={RouterLink}>
          recrutements
        </Link>
        , les{" "}
        <Link to="/matches" component={RouterLink}>
          propositions client
        </Link>
        , les{" "}
        <Link to="/needs" component={RouterLink}>
          besoins client
        </Link>
        , etc.
      </Typography>
    </Box>
  );
}

export default Dashboard;
