import { Link } from "@mui/material";
import { format } from "phone-fns";
import { useRecordContext } from "react-admin";

export default function PhoneNumberField({ label }) {
  const record = useRecordContext();
  if (!record?.phoneNumber) return null;
  return (
    <Link href={`tel:${record.phoneNumber}`} alt={label} noWrap variant="body2">
      {format("NN NN NN NN NN", record.phoneNumber)}
    </Link>
  );
}

PhoneNumberField.defaultProps = {
  label: "Numéro de téléphone",
};
