import pluralize from "../../utils/pluralize";

export default function formatSeniorityLevelText(record = {}) {
  const experienceRequirements = [
    record.experience?.min,
    record.experience?.max,
  ].filter(Boolean);
  const yearsAnnotation =
    experienceRequirements?.length > 0
      ? `(${experienceRequirements.join(" - ")} ${pluralize(
          "année",
          record.experience?.min || record.experience?.max,
        )})`
      : null;
  return [record.name, yearsAnnotation].filter(Boolean).join(" ");
}
