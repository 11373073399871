import { Bookmark } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import NeedCreate from "./NeedCreate";
import NeedEdit from "./NeedEdit";
import NeedList from "./NeedList";

const needs = {
  create: NeedCreate,
  edit: NeedEdit,
  list: NeedList,
  icon: Bookmark,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Besoins" },
};

export default needs;
