/**
 * Calculate the union number ranges
 * @function unionNumberRanges
 * @param {[[Number, Number]]} ranges
 * @returns {[[Number, Number]]} ranges
 */
const unionNumberRanges = (ranges = []) => {
  return ranges
    .map((range) => range.sort((a, b) => a - b))
    .sort((a, b) => a[0] - b[0])
    .reduce((acc, next) => {
      if (!acc.length) return [...acc, next];
      const prev = acc[acc.length - 1];
      if (next[0] > prev[1]) return [...acc, next];
      return [
        ...acc.slice(0, acc.length - 1),
        [prev[0], Math.max(prev[1], next[1])],
      ];
    }, []);
};

export default unionNumberRanges;
