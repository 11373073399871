import { FindReplace } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import MatchStatusCreate from "./MatchStatusCreate";
import MatchStatusEdit from "./MatchStatusEdit";
import MatchStatusList from "./MatchStatusList";

const matchStatuses = {
  create: MatchStatusCreate,
  edit: MatchStatusEdit,
  list: MatchStatusList,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Statuts de proposition" },
  icon: FindReplace,
};

export default matchStatuses;
