import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";

import { TO_CONTACT, TO_SURVEY } from "../profileStatuses/statuses";

export default function RecruitmentForm(props) {
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        label="Profile"
        source="profile"
        reference="profiles"
        sort={{ field: "createdAt", order: "DESC" }}
        filter={{ status: [TO_CONTACT, TO_SURVEY] }}
      >
        <AutocompleteInput
          optionText={(record) =>
            [record?.firstname, record?.lastname].filter(Boolean).join(" ")
          }
          fullWidth
        />
      </ReferenceInput>
      <ReferenceInput
        label="Statuts"
        source="status"
        reference="recruitmentStatuses"
      >
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
    </SimpleForm>
  );
}
