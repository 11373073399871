import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, Grid, TextField, Toolbar } from "@mui/material";
import { arrayOf, shape } from "prop-types";
import { useMemo } from "react";
import React, { useState } from "react";
import {
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from "react-admin";

import useCurrentUser from "../../utils/useCurrentUser";
import CommentCard, { CommentProps } from "../CommentCard";

function CommentInput() {
  const { user } = useCurrentUser();
  const resource = useResourceContext();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [value, setValue] = useState("");
  const onChange = (event) => {
    setValue(event.target.value);
  };
  const [update, { isLoading, error }] = useUpdate();
  const onKeyDown = (event) => {
    if (!event.shiftKey && event.key === "Enter" && value.trim().length > 0) {
      const diff = {
        comments: [
          ...(record.comments || []),
          { createdAt: Date.now(), content: value, author: user.id },
        ].sort((a, b) => a.createdAt - b.createdAt),
      };
      update(
        resource,
        { id: record.id, data: diff, previousData: record },
        {
          onSuccess: () => {
            refresh();
          },
        },
      );
      setValue("");
    }
  };
  return (
    <TextField
      fullWidth
      multiline
      minRows={3}
      disabled={isLoading}
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
      error={!!error}
      placeholder="Écrire un commentaire"
    />
  );
}

export default function CommentsField(props) {
  const record = useRecordContext();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const MAX = 3;
  const [mostRecentComments, otherComments] = useMemo(() => {
    const sortedComments = (record.comments || []).sort(
      (a, b) => b?.createdAt - a?.createdAt,
    );
    return [
      sortedComments.slice(0, MAX),
      sortedComments.slice(MAX, sortedComments.length),
    ];
  }, [record.comments]);

  return (
    <Box p={2}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <CommentInput
            source="comments"
            resource={props.resource}
            record={record}
          />
        </Grid>
        {mostRecentComments.map((comment) => (
          <Grid item key={comment.createdAt}>
            <CommentCard {...props} {...comment} />
          </Grid>
        ))}
      </Grid>
      {otherComments.length > 0 && (
        <>
          <Toolbar disableGutters>
            <Button
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={expanded ? "Voir moins" : "Voir plus"}
              endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            >
              {expanded ? "Voir moins" : "Voir plus"}
            </Button>
          </Toolbar>
          <Collapse in={expanded} timeout="auto">
            <Grid container direction="column" spacing={4}>
              {otherComments.map((comment) => (
                <Grid item key={comment.createdAt}>
                  <CommentCard {...props} {...comment} />
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </>
      )}
    </Box>
  );
}

CommentsField.propTypes = {
  record: shape({ comments: arrayOf(shape(CommentProps)) }),
};

CommentsField.defaultProps = { addLabel: true, record: {} };
