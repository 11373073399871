import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";

export default function FullNameField(props) {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Typography noWrap variant="body2" {...props}>
      {record.firstname} {record.lastname}
    </Typography>
  );
}
