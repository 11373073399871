import {
  Archive,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  ChipField,
  RecordContext,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  useCreatePath,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { Link as RouterLink } from "react-router-dom";

import CloseRecruitmentButton from "./CloseRecruitmentButton";
import ContractTypesField from "./ContractTypeField";
import DailyRateField from "./DailyRateField";
import TitleField from "./TitleField";
import TotalXPField from "./TotalXPField";

export default function RecruitmentCard(props) {
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const {
    isLoading,
    error,
    data: profile,
  } = useGetOne(
    "profiles",
    { id: record?.profile },
    { enabled: Boolean(record?.profile) },
  );

  if (isLoading || error) return null;
  const hasXp = profile?.experiences?.length >= 1;
  return (
    <RecordContext.Provider value={profile}>
      <Card {...props} onClick={toggleExpand}>
        <CardHeader
          disableTypography
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitleField variant="h5" fontWeight={700} />
              <IconButton onClick={toggleExpand}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Stack>
          }
        />
        {expanded && (
          <>
            <CardActions>
              <Button
                component={RouterLink}
                to={createPath({
                  type: "show",
                  resource: "profiles",
                  id: profile.id,
                })}
                size="small"
                startIcon={<RemoveRedEye />}
              >
                Voir le profil
              </Button>
            </CardActions>
            <Stack component={CardContent} spacing={2}>
              {hasXp ? (
                <Stack spacing={1} alignItems="flex-start">
                  <ReferenceField
                    reference="seniorityLevels"
                    source="seniority"
                    link={false}
                  >
                    <ChipField source="name" size="small" />
                  </ReferenceField>
                  <ReferenceArrayField source="skills" reference="skills">
                    <SingleFieldList
                      component={Stack}
                      direction="row"
                      alignItems="center"
                    >
                      <ChipField source="name" size="small" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <TotalXPField source="experiences" />
                </Stack>
              ) : (
                <Typography variant="body2">
                  Pas encore d'xp, en{" "}
                  <Link component={RouterLink} to={`/profiles/${profile.id}/1`}>
                    ajouter
                  </Link>
                  .
                </Typography>
              )}
              <Stack spacing={1} alignItems="flex-start">
                <ContractTypesField source="contractTypes" />
                <DailyRateField label="TJM min." source="dailyRate" />
              </Stack>
            </Stack>
            <CardActions>
              <CloseRecruitmentButton
                record={record}
                color="error"
                size="small"
                startIcon={<Archive />}
              />
            </CardActions>
          </>
        )}
      </Card>
    </RecordContext.Provider>
  );
}
