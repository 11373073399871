import { MenuBook } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import SkillCreate from "./SkillCreate";
import SkillEdit from "./SkillEdit";
import SkillList from "./SkillList";

const roles = {
  create: SkillCreate,
  edit: SkillEdit,
  list: SkillList,
  icon: MenuBook,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Compétences" },
};

export default roles;
