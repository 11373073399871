import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from "ra-core";

import isStatusOk from "../utils/isStatusOk";
import log from "../utils/log";
import dataProvider from "./dataProvider";
import getUserLogin from "./getUserLogin";
import initialize from "./initialize";
import parseUser from "./parseUser";

const app = initialize();
const auth = getAuth(app);

async function login() {
  log(AUTH_LOGIN);
  await setPersistence(auth, browserLocalPersistence);
  const provider = new GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  await signInWithPopup(auth, provider);
}

async function logout() {
  log(AUTH_LOGOUT);
  await signOut(auth);
}

async function checkAuth() {
  log(AUTH_CHECK);
  try {
    const user = await getUserLogin(auth);
    if (!user) {
      return Promise.reject({ redirectTo: "/login" });
    }
  } catch (error) {
    return Promise.reject({ redirectTo: "/login" });
  }
}

async function checkError(error) {
  log(AUTH_ERROR);
  if (!isStatusOk(error?.status)) {
    return Promise.reject({ redirectTo: "/login" });
  }
}

async function getPermissions() {
  log(AUTH_GET_PERMISSIONS);
  try {
    const user = await getUserLogin(auth);
    if (!user) {
      return [];
    }
    const identityId = user?.uid || null;
    const users = await dataProvider.getList("users", {
      filter: { identityId },
    });
    const permissions = [users.data[0]?.role].filter(Boolean);
    return permissions;
  } catch (error) {
    return [];
  }
}

async function getIdentity() {
  log("GET_IDENTITY");
  try {
    const user = await getUserLogin(auth);
    if (!user) return null;
    return parseUser(user);
  } catch (error) {
    return null;
  }
}

const authProvider = {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
  getIdentity,
};

export default authProvider;
