import { Create } from "react-admin";

import ResumeForm from "./ResumeForm";

export default function ResumeCreate(props) {
  return (
    <Create {...props} redirect="list">
      <ResumeForm />
    </Create>
  );
}
