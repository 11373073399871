import { Create } from "react-admin";

import ProfileStatusForm from "./ProfileStatusForm";

export default function ProfileStatusCreate(props) {
  return (
    <Create {...props}>
      <ProfileStatusForm />
    </Create>
  );
}
