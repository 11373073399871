import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

function CreateResumeButton() {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Button
      component={Link}
      to={{
        pathname: "/resumes/create",
      }}
      state={{
        record: {
          profile: record.id,
          title: record.jobtitle,
          skills: record.skills,
        },
      }}
      startIcon={<Add />}
      color="primary"
    >
      Créer une fiche de compétences
    </Button>
  );
}

export default CreateResumeButton;
