import { Edit } from "react-admin";

import BadgeForm from "./BadgeForm";

export default function BadgeEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <BadgeForm />
    </Edit>
  );
}
