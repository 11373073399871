import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

import DatePickerInput from "../../components/DatePickerInput";
import formatSeniorityLevelText from "../../resources/seniorityLevels/formatSeniorityLevelText";

export default function JobForm(props) {
  return (
    <SimpleForm
      toolbar={
        <Toolbar
          sx={{
            position: "sticky",
            bottom: 0,
            zIndex: 10,
            width: "100%",
          }}
        >
          <SaveButton redirect="list" />
        </Toolbar>
      }
      defaultValues={{
        active: true,
        location: "Bordeaux",
        startingDate: Date.now(),
        description: "",
      }}
      {...props}
    >
      <BooleanInput label="Activée" source="active" />
      <TextInput
        label="Titre"
        source="title"
        validate={[required()]}
        fullWidth
      />
      <ReferenceArrayInput
        source="seniorities"
        reference="seniorityLevels"
        sort={{
          field: "experience.min",
          order: "ASC",
        }}
      >
        <AutocompleteArrayInput
          label="Séniorités"
          optionText={formatSeniorityLevelText}
          fullWidth
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="mainSkills"
        reference="skills"
        sort={{ field: "name", order: "ASC" }}
        filter={{ type: "hard" }}
      >
        <AutocompleteArrayInput
          label="Stack technique"
          optionText="name"
          fullWidth
        />
      </ReferenceArrayInput>
      <TextInput
        label="Localisation"
        source="location"
        validate={[required()]}
        fullWidth
      />
      <BooleanInput label="Avec responsabilité" source="withResponsability" />
      <DatePickerInput
        label="Date de démarrage"
        source="startingDate"
        fullWidth
      />
      <TextInput source="description" fullWidth multiline rows={3} />
      <TextInput
        label="Lien de candidature"
        source="applicationUrl"
        fullWidth
      />
    </SimpleForm>
  );
}
