import { useGetIdentity, useGetList } from "react-admin";

function useCurrentUser() {
  const { data: identity } = useGetIdentity();
  const identityId = identity?.id;
  const {
    data = [],
    isLoading,
    error,
  } = useGetList(
    "users",
    {
      sort: {},
      pagination: {},
      filter: {
        identityId,
      },
    },
    { enabled: Boolean(identityId) },
  );
  if (isLoading || error || !identityId) return { identity };
  const user = data[0];
  return { user, identity };
}

export default useCurrentUser;
