import { Add } from "@mui/icons-material";
import { Button, Stack, Toolbar } from "@mui/material";
import React from "react";
import { Title } from "react-admin";
import { Link as RouterLink } from "react-router-dom";

import Board from "../../components/Board";
import RecruitmentCard from "../../components/RecruitmentCard";
import {
  GENERAL_REVIEW,
  REFINEMENT,
  TECHNICAL_REVIEW,
  VALIDATED,
} from "../recruitmentStatuses/statuses";

export default function RecruitmentBoard() {
  return (
    <>
      <Title title="Recrutements" />
      <Toolbar>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          <Button
            variant="contained"
            component={RouterLink}
            to="/recruitments/create"
            startIcon={<Add />}
            size="small"
          >
            Démarrer
          </Button>
        </Stack>
      </Toolbar>
      <Board
        resource="recruitments"
        basePath="/recruitments"
        columnReference="recruitmentStatuses"
        ids={[GENERAL_REVIEW, TECHNICAL_REVIEW, REFINEMENT, VALIDATED]}
        CardComponent={RecruitmentCard}
      />
    </>
  );
}
