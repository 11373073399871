import merge from "lodash/merge";
import polyglotI18nProvider from "ra-i18n-polyglot";
import fr from "ra-language-french";

import frenchMessages from "./frenchMessages";

const messages = {
  fr: merge(fr, frenchMessages),
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "fr");

export default i18nProvider;
