import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useGetList, useRefresh, useUpdate } from "react-admin";

import Draggable from "../Draggable";

export default function Matrice({
  resource,
  columnIds,
  columnReference,
  columnTarget,
  columnLabel,
  rowReference,
  rowTarget,
  rowLabel,
  CellComponent,
}) {
  const width = `${Math.trunc(100 / (columnIds.length + 1))}%`;
  const {
    isLoading: isLoadingColumns,
    error: errorColumns,
    data: columns = [],
  } = useGetList(columnReference, {
    filter: {
      id: columnIds,
    },
  });
  const {
    isLoading: isLoadingRecords,
    error: errorRecords,
    data: records = [],
  } = useGetList(
    resource,
    {
      sort: {
        field: "updatedAt",
        order: "DESC",
      },
    },
    {
      enabled: columns.length > 0,
    },
  );
  const rowIds = useMemo(
    () => Array.from(new Set(records.map((record) => record[rowTarget]))),
    [records, rowTarget],
  );
  const {
    isLoading: isLoadingRows,
    error: errorRows,
    data: rows = [],
  } = useGetList(
    rowReference,
    {
      filter: {
        id: rowIds,
      },
    },
    {
      enabled: rowIds.length > 0,
    },
  );
  const [update] = useUpdate();
  const refresh = useRefresh();
  const onDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);
  const onDragStart = useCallback(
    (id, record) => (event) => {
      event.dataTransfer.setData("id", id);
      event.dataTransfer.setData("record", JSON.stringify(record));
    },
    [],
  );
  const onDrop = useCallback(
    (id) => (event) => {
      const prevId = event.dataTransfer.getData("id");
      const record = JSON.parse(event.dataTransfer.getData("record"));
      if (id !== prevId) {
        update(
          resource,
          {
            id: record.id,
            data: { ...record, [columnTarget]: id },
            previousData: record,
          },
          {
            onSuccess: refresh,
          },
        );
      }
    },
    [columnTarget, refresh, resource, update],
  );
  if (
    isLoadingColumns ||
    isLoadingRecords ||
    isLoadingRows ||
    errorColumns ||
    errorRecords ||
    errorRows
  )
    return null;
  return (
    <Box component="table" width="100%">
      <thead>
        <tr>
          <Box component="th" p={1} textAlign="left" width={width} />
          {columnIds.map((columnId) => (
            <Box
              component="th"
              p={1}
              textAlign="left"
              width={width}
              key={`header-${columnId}`}
            >
              <Typography variant="h6" color="textSecondary">
                {columnLabel(columns.find((column) => column.id === columnId))}
              </Typography>
            </Box>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowIds.map((rowId) => {
          const row = rows.find((row) => row.id === rowId);
          if (!row) return null;
          return (
            <tr key={rowId}>
              <Box component="td" p={1} textAlign="left" width={width}>
                <Typography variant="h6">{rowLabel(row)}</Typography>
              </Box>
              {columnIds.map((columnId) => {
                const chips = records.filter(
                  (record) =>
                    record[rowTarget] === rowId &&
                    record[columnTarget] === columnId,
                );
                return (
                  <Box
                    component="td"
                    p={1}
                    textAlign="left"
                    width={width}
                    key={`${rowId}-${columnId}`}
                    onDrop={onDrop(columnId)}
                    onDragOver={onDragOver}
                  >
                    <Grid container spacing={1}>
                      {chips.map((chip) => (
                        <Grid item key={chip.id}>
                          <Draggable onDragStart={onDragStart(columnId, chip)}>
                            <CellComponent record={chip} />
                          </Draggable>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Box>
  );
}
