import { ExitToApp } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { forwardRef } from "react";
import { useGetIdentity, useLogin, useLogout, useUserMenu } from "react-admin";

import GoogleIcon from "./GoogleIcon";

const LogoutMenu = forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const logout = useLogout();
  const login = useLogin();
  const { data: identity, isLoading, error } = useGetIdentity();
  if (isLoading) return null;
  if (error || !identity) {
    const onLogin = async () => {
      await login();
      window.location.reload();
      onClose();
    };
    return (
      <MenuItem ref={ref} {...props} onClick={onLogin}>
        <ListItemIcon>
          <GoogleIcon />
        </ListItemIcon>
        <ListItemText>Se connecter avec Google</ListItemText>
      </MenuItem>
    );
  }
  const onLogout = async () => {
    await logout();
    onClose();
  };
  return (
    <MenuItem ref={ref} {...props} onClick={onLogout}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText>Se déconnecter</ListItemText>
    </MenuItem>
  );
});

export default LogoutMenu;
