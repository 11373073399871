import { Edit } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import SeniorityLevelForm from "./SeniorityLevelForm";

export default function SeniorityLevelEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <SeniorityLevelForm toolbar={<FormToolbar />} />
    </Edit>
  );
}
