import { Button, Typography } from "@mui/material";
import { useRecordContext, useRefresh, useUpdate } from "react-admin";

import { CLOSED } from "../resources/recruitmentStatuses/statuses";

const CloseRecruitmentButton = ({
  children = "Fermer le recrutement",
  ...props
}) => {
  const record = useRecordContext(props);
  const refresh = useRefresh();
  const [update, { isLoading, error }] = useUpdate(
    "recruitments",
    { id: record?.id, data: { status: CLOSED }, previousData: record },
    {
      enabled: Boolean(record?.id),
      onSuccess: () => {
        refresh();
      },
    },
  );
  const onClose = () => {
    update();
  };
  return (
    <>
      <Button disabled={isLoading} onClick={onClose} {...props}>
        {children}
      </Button>
      {error && (
        <Typography variant="caption" color="error">
          Failed to close the recruitment, please try again.
        </Typography>
      )}
    </>
  );
};
export default CloseRecruitmentButton;
