import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default function MatchEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <SimpleForm>
        <ReferenceInput source="profile" reference="profiles">
          <SelectInput
            label="Profil"
            optionText={(record) =>
              [record?.firstname, record?.lastname].filter(Boolean).join(" ")
            }
            disabled
            fullWidth
          />
        </ReferenceInput>
        <TextInput source="client" fullWidth />
        <ReferenceInput source="status" reference="matchStatuses">
          <SelectInput label="Statut" optionText="name" fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
