import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
} from "react-admin";
import { useSearchParams } from "react-router-dom";

import compareExperiences from "../../utils/compareExperiences";

function transform(dataProvider) {
  return async (data) => {
    const { data: profile } = await dataProvider.getOne("profiles", {
      id: data.profile,
    });

    const experiences = (
      await Promise.all(
        (profile.experiences || []).map((experience) =>
          dataProvider
            .getMany("skills", {
              ids: experience?.skills || [],
            })
            .then(({ data: skills }) => ({ ...experience, skills })),
        ),
      )
    ).sort(compareExperiences);

    const { data: badges } = await dataProvider.getMany("badges", {
      ids: profile.badges || [],
    });

    const { data: mainSkills } = await dataProvider.getMany("skills", {
      ids: profile.skills || [],
    });

    const name = (
      profile.firstname.slice(0, 2) + profile.lastname.slice(0, 1)
    ).toUpperCase();

    return {
      ...data,
      intro: profile.intro || "",
      badges,
      mainSkills,
      experiences,
      name,
      formations: profile.formations || [],
    };
  };
}

export default function ResumeForm(props) {
  const dataProvider = useDataProvider();
  const [searchParams] = useSearchParams();
  return (
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton
            redirect="list"
            transform={transform(dataProvider)}
            type="button"
            alwaysEnable
          />
        </Toolbar>
      }
      {...props}
    >
      <TextInput
        label="Titre"
        source="title"
        validate={[required()]}
        defaultValue={searchParams.get("title")}
        fullWidth
      />
      <ReferenceInput
        source="profile"
        reference="profiles"
        validate={[required()]}
      >
        <AutocompleteInput
          label="Profil"
          optionText={(record) =>
            [record?.firstname, record?.lastname].filter(Boolean).join(" ")
          }
          defaultValue={searchParams.get("profile")}
          fullWidth
        />
      </ReferenceInput>
      <ReferenceArrayInput
        source="skills"
        reference="skills"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteArrayInput
          label="Compétences"
          optionText="name"
          defaultValue={searchParams.get("skills")}
          fullWidth
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
}
