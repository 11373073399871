import { SvgIcon } from "@mui/material";

export default function GitLab(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="100 80 389 389"
      {...props}
    >
      <path d="M461.48 298.35l-17.78-54.63a7.72 7.72 0 00-.43-1.47l-35.67-109.8a14.18 14.18 0 00-13.54-9.67 13.94 13.94 0 00-13.38 9.75l-34 104.63H239.37l-34.05-104.63a13.94 13.94 0 00-13.32-9.75h-.08a14.22 14.22 0 00-13.5 9.76l-35.7 109.93c0 .1-.08.18-.11.28l-18.1 55.61a20.29 20.29 0 007.37 22.71L288.26 434.7a8 8 0 009.45-.05l156.41-113.58a20.28 20.28 0 007.36-22.72m-233.75-45.13l43.59 134.16-104.64-134.16m148.05 134.19l41.8-128.62 1.8-5.57h61.1L324.76 374.5m69.44-231.67l30.63 94.33h-61.31m-22.03 16l-30.37 93.46L293 402.28l-48.42-149.12m-52.73-110.33l30.69 94.33h-61.27m-19.98 70.97a4.31 4.31 0 01-1.56-4.83l13.44-41.3 98.57 126.37m192.98-80.24l-110.46 80.21.37-.48L432.83 262l13.44 41.28a4.31 4.31 0 01-1.55 4.84" />
    </SvgIcon>
  );
}
