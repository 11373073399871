import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

import { getBadgeIcon } from "./badges";

export default function BadgeField() {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Chip
      label={record?.name}
      avatar={getBadgeIcon(record?.name)}
      sx={{ m: 0.5 }}
    />
  );
}
