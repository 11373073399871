import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from "react-admin";

function RoleFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
    </Filter>
  );
}

export default function RoleList(props) {
  return (
    <List
      {...props}
      sort={{ field: "updatedAt", order: "ASC" }}
      filters={<RoleFilter />}
    >
      <Datagrid>
        <TextField label="Nom" source="name" />
        <BooleanField label="Avec responsabilité" source="withResponsability" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
