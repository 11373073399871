import {
  ChipField,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import FullNameField from "../../components/FullNameField";

function ResumeFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Recherche" source="q" alwaysOn />
    </Filter>
  );
}

export default function ResumeList(props) {
  return (
    <List
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      filters={<ResumeFilter />}
    >
      <Datagrid>
        <TextField label="Titre" source="title" />
        <ReferenceField
          label="Profil"
          source="profile"
          reference="profiles"
          link="show"
        >
          <FullNameField />
        </ReferenceField>
        <ReferenceArrayField
          label="Compétences"
          source="skills"
          reference="skills"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
