import {
  Accessibility,
  Build,
  DirectionsRun,
  Domain,
  QuestionAnswer,
  Share,
} from "@mui/icons-material";

const badges = [
  { name: "Agiliste", icon: DirectionsRun },
  { name: "Artisan du code", icon: Build },
  { name: "DevOps", icon: Domain },
  { name: "Open Source", icon: Share },
  { name: "Ergonome", icon: Accessibility },
  { name: "Orateur", icon: QuestionAnswer },
];

export function getBadgeIcon(name = "") {
  return badges.find((badge) => badge.name === name)?.icon;
}

export default badges;
