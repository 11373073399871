function removeDiacritics(string = "") {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function escapeSpecialCaracters(string = "") {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export default function search(doc = {}, q = "") {
  return Object.keys(doc).some((field) => {
    const value = doc[field];
    const regex = new RegExp(removeDiacritics(escapeSpecialCaracters(q)), "i");
    if (typeof value === "number") return value.toString().match(regex);
    if (typeof value === "string") return removeDiacritics(value).match(regex);
    return false;
  });
}
