import { Create } from "react-admin";

import MatchStatusForm from "./MatchStatusForm";

export default function MatchStatusCreate(props) {
  return (
    <Create {...props}>
      <MatchStatusForm />
    </Create>
  );
}
