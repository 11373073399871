import { Grid, Typography } from "@mui/material";
import { arrayOf, shape } from "prop-types";
import React, { useMemo } from "react";
import { useRecordContext } from "react-admin";

import FormationCard, { FormationProps } from "../FormationCard";

export default function FormationsField({ source, ...props }) {
  const record = useRecordContext();
  const formations = useMemo(
    () => record.formations?.sort((a, b) => b?.endedAt - a?.endedAt) || [],
    [record.formations],
  );
  if (formations.length === 0)
    return <Typography variant="caption">Pas de formation.</Typography>;
  return (
    <Grid container spacing={4} direction="column">
      {formations.map((formationProps) => (
        <Grid
          item
          key={`${formationProps.startedAt}-${formationProps.endedAt}`}
        >
          <FormationCard {...props} {...formationProps} />
        </Grid>
      ))}
    </Grid>
  );
}

FormationsField.propTypes = {
  record: shape({ formations: arrayOf(shape(FormationProps)) }),
};

FormationsField.defaultProps = { addLabel: true, record: {} };
