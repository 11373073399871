import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";
import { Admin } from "react-admin";

import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import LoginPage from "./components/LoginPage";
import NotFound from "./components/NotFound";
import authProvider from "./firebase/authProvider";
import dataProvider from "./firebase/dataProvider";
import theme from "./theme";
import i18nProvider from "./utils/i18nProvider";
import renderResources from "./utils/renderResources";

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <Admin
        authProvider={authProvider}
        catchAll={NotFound}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
        theme={theme}
        dashboard={Dashboard}
      >
        {renderResources}
      </Admin>
    </LocalizationProvider>
  );
};

export default App;
