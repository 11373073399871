import { Button, CardActions, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { Login, useLogin } from "react-admin";

import GoogleIcon from "./GoogleIcon";

const LoginPage = (props) => {
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const onLogin = async () => {
    try {
      setLoading(true);
      await login();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Login {...props}>
      <CardContent>
        <Typography>
          Ravi de te revoir sur{" "}
          <Typography component="span" fontWeight={700}>
            Tatou
          </Typography>{" "}
          !{" "}
          <Typography component="span" role="img" aria-label="waving hand">
            👋
          </Typography>
        </Typography>
        <Typography>Tu dois te connecter avant d'y accéder.</Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={onLogin}
          startIcon={<GoogleIcon />}
          fullWidth
          variant="contained"
          disabled={loading}
        >
          Se connecter avec Google
        </Button>
      </CardActions>
    </Login>
  );
};

export default LoginPage;
