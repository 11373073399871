import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { PROPOSED } from "../matchStatuses/statuses";
import {
  IN_RECRUITMENT,
  ONBOARDED,
  ONBOARDING,
} from "../profileStatuses/statuses";

export default function MatchCreate(props) {
  return (
    <Create {...props} redirect="list">
      <SimpleForm defaultValues={{ status: PROPOSED }}>
        <ReferenceInput
          source="profile"
          reference="profiles"
          sort={{ field: "createdAt", order: "DESC" }}
          filter={{
            status: [IN_RECRUITMENT, ONBOARDED, ONBOARDING],
          }}
        >
          <AutocompleteInput
            label="Profil"
            optionText={(record) =>
              [record?.firstname, record?.lastname].filter(Boolean).join(" ")
            }
            resettable
            fullWidth
          />
        </ReferenceInput>
        <TextInput source="client" fullWidth />
        <ReferenceInput source="status" reference="matchStatuses">
          <SelectInput
            label="Statut"
            optionText="name"
            defaultValue={null}
            fullWidth
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
