import { Warning } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import {
  AutocompleteArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  useRecordContext,
} from "react-admin";

const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Typography
          component="span"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {record.name}
          {formData.id === record.id && (
            <Tooltip title="Statut en cours de modification">
              <Warning sx={{ ml: 1 }} fontSize="small" color="disabled" />
            </Tooltip>
          )}
        </Typography>
      )}
    </FormDataConsumer>
  );
};

const inputText = (choice) => choice.name;

const matchSuggestion = (filter, choice) => {
  return choice.name.toLowerCase().includes(filter.toLowerCase());
};

const NextStatusInput = ({ source, reference, ...props }) => {
  return (
    <ReferenceArrayInput source={source} reference={reference}>
      <AutocompleteArrayInput
        optionText={<OptionRenderer />}
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        {...props}
      />
    </ReferenceArrayInput>
  );
};

export default NextStatusInput;
