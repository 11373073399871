import {
  ChipField,
  Datagrid,
  EditButton,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from "react-admin";

export default function MatchStatusList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField label="Nom" source="name" />
        <ReferenceArrayField
          label="Prochains statuts"
          reference="matchStatuses"
          source="next"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
      </Datagrid>
    </List>
  );
}
