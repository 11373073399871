export default function parseUser({
  uid,
  displayName = "",
  photoURL = "",
  email = null,
}) {
  if (!uid) return null;
  return {
    id: uid,
    fullName: displayName,
    avatar: photoURL,
    email,
  };
}
