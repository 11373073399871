import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "ra-core";

import list from "../utils/list";
import log from "../utils/log";
import initialize from "./initialize";
import parseSnapshot from "./parseSnapshot";

const app = initialize();
const db = getFirestore(app);

async function getList(resource, params) {
  try {
    log(GET_LIST, resource, params);
    const queryConstraints = [
      params.sort?.field && params.sort?.order
        ? orderBy(params.sort.field, params.sort.order.toLowerCase())
        : null,
    ].filter(Boolean);
    const snapshot = await getDocs(
      query(collection(db, resource), ...queryConstraints),
    );
    return list(snapshot.docs.map(parseSnapshot), params);
  } catch (error) {
    return { data: [], total: 0 };
  }
}

async function create(resource, params) {
  log(CREATE, resource, params);
  const createdAt = Date.now();
  const payloadAdd = pickBy(
    {
      ...params.data,
      createdAt,
      updatedAt: createdAt,
    },
    identity,
  );
  const ref = await addDoc(collection(db, resource), payloadAdd);
  const payloadUpdate = {
    id: ref.id,
    data: { id: ref.id },
  };
  const responseUpdate = await update(resource, payloadUpdate);
  return {
    data: {
      ...payloadAdd,
      ...responseUpdate.data,
    },
  };
}

async function update(resource, params) {
  log(UPDATE, resource, params);
  if (params.id) {
    const updatedAt = Date.now();
    const payload = pickBy({ ...params.data, updatedAt }, identity);
    await setDoc(doc(db, resource, params.id), payload, { merge: true });
    return {
      data: {
        id: params.id,
        ...payload,
      },
    };
  } else {
    throw new Error("Id is missing from params.");
  }
}

async function getOne(resource, params) {
  log(GET_ONE, resource, params);
  if (params.id) {
    const snapshot = await getDoc(doc(db, resource, params.id));
    if (snapshot.exists) {
      const data = parseSnapshot(snapshot);
      return { data };
    } else {
      throw new Error(`Record ${resource}#${params.id} not found.`);
    }
  } else {
    throw new Error("Id is missing from params.");
  }
}

async function deleteOne(resource, params) {
  log(DELETE, resource, params);
  if (params.id) {
    await deleteDoc(doc(db, resource, params.id));
    return { data: params.id };
  } else {
    throw new Error("Id is missing from params.");
  }
}

async function deleteMany(resource, params) {
  log(DELETE_MANY, resource, params);
  const data = await Promise.all(
    params.ids.map(async (id) => {
      await deleteOne(resource, { id });
      return id;
    }),
  );
  return { data };
}

async function getMany(resource, params) {
  try {
    log(GET_MANY, resource, params);
    const snapshot = await getDocs(query(collection(db, resource)));
    const { data } = list(snapshot.docs.map(parseSnapshot), {
      filter: { id: params.ids },
    });
    return {
      data,
    };
  } catch (error) {
    return { data: [] };
  }
}

async function getManyReference(resource, params) {
  log(GET_MANY_REFERENCE, resource, params);
  if (params.target) {
    if (!params.filter) params.filter = {};
    params.filter[params.target] = params.id;
    return await getList(resource, params);
  } else {
    throw new Error("Target is missing from params.");
  }
}

async function updateMany(resource, params) {
  log(UPDATE_MANY, resource, params);
  const data = await Promise.all(
    params.ids.map(async (id) => {
      await update(resource, { id, data: params.data });
      return id;
    }),
  );
  return { data };
}

const dataProvider = {
  create,
  delete: deleteOne,
  deleteMany,
  getList,
  getMany,
  getManyReference,
  getOne,
  update,
  updateMany,
};

export default dataProvider;
