import { Edit } from "react-admin";

import ResumeForm from "./ResumeForm";

export default function ResumeEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <ResumeForm />
    </Edit>
  );
}
