import { Stack } from "@mui/material";
import {
  AutocompleteArrayInput,
  ChipField,
  Datagrid,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import ContractTypesField, {
  contractTypes,
} from "../../components/ContractTypeField";
import FullNameField from "../../components/FullNameField";

const contractTypeChoices = Object.keys(contractTypes).map((id) => ({
  id,
  name: contractTypes[id],
}));

function ProfileFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Recherche" source="q" alwaysOn />
      <ReferenceArrayInput source="status" reference="statuses" alwaysOn>
        <AutocompleteArrayInput label="Statuts" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="seniorityLevel"
        reference="seniorityLevels"
        alwaysOn
      >
        <AutocompleteArrayInput label="Niveau de séniorité" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="skills"
        reference="skills"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <AutocompleteArrayInput label="Compétences" optionText="name" />
      </ReferenceArrayInput>
      <AutocompleteArrayInput
        label="Types de contrat"
        source="contractTypes"
        choices={contractTypeChoices}
        fullWidth
        alwaysOn
      />
    </Filter>
  );
}

export default function ProfileList(props) {
  return (
    <List
      {...props}
      sx={{ mt: 2 }}
      filters={<ProfileFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <FullNameField label="Nom et prénom" variant="body2" />
        <TextField label="Titre" source="jobtitle" />
        <ReferenceField
          label="Statut"
          reference="statuses"
          source="status"
          link={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>
        <ReferenceField
          label="Niveau de séniorité"
          reference="seniorityLevels"
          source="seniority"
          link={false}
        >
          <ChipField source="name" size="small" />
        </ReferenceField>
        <ReferenceArrayField
          label="Compétences"
          source="skills"
          reference="skills"
        >
          <SingleFieldList
            component={Stack}
            direction="row"
            alignItems="center"
          >
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ContractTypesField label="Types de contrat" source="contractTypes" />
      </Datagrid>
    </List>
  );
}
