import { FormHelperText, Link } from "@mui/material";
import { useCreatePath, useGetList, useResourceContext } from "react-admin";
import { useWatch } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

export default function HomonymHelpertext() {
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const [firstname, lastname] = useWatch({ name: ["firstname", "lastname"] });
  const { isLoading, error, total } = useGetList(resource, {
    filter: { firstname, lastname },
  });
  if (isLoading || error || total === 0) return null;
  return (
    <FormHelperText>
      Attention, le profil existe peut-être déjà.{" "}
      <Link
        component={RouterLink}
        to={{
          pathname: createPath({
            resource,
            type: "list",
          }),
          search: `filter=${JSON.stringify({ firstname, lastname })}`,
        }}
      >
        Voir les homonymes
      </Link>
      .
    </FormHelperText>
  );
}
