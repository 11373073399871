import { Edit, TextField } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import UserForm from "./UserForm";

export default function UserEdit(props) {
  return (
    <Edit
      {...props}
      title={<TextField component="span" source="fullName" variant="h6" />}
      redirect="list"
    >
      <UserForm permissions={props.permissions} toolbar={<FormToolbar />} />
    </Edit>
  );
}
