import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

import FullNameField from "../../components/FullNameField";

export default function ProfileList(props) {
  return (
    <List {...props} sort={{ field: "updatedAt", order: "DESC" }}>
      <Datagrid>
        <ReferenceField label="Profil" source="profile" reference="profiles">
          <FullNameField />
        </ReferenceField>
        <TextField source="client" />
        <ReferenceField
          label="Statut"
          reference="matchStatuses"
          source="status"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
