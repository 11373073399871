import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  SelectField,
  TextField,
} from "react-admin";

import choices from "../../utils/roleChoices";

export default function UserList(props) {
  return (
    <List {...props} sort={{ field: "updatedAt", order: "DESC" }}>
      <Datagrid>
        <TextField label="Nom et prénom" source="fullName" />
        <TextField label="Adresse email" source="email" />
        <SelectField label="Rôle" source="role" choices={choices} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
