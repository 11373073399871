import { Add } from "@mui/icons-material";
import { Button, Chip, Toolbar } from "@mui/material";
import React from "react";
import { Title } from "react-admin";
import { Link as RouterLink } from "react-router-dom";

import Matrice from "../../components/Matrice";
import {
  CLOSED_BY_CLIENT,
  CLOSED_BY_US,
  ON_REVIEW,
  PROPOSED,
  VALIDATED,
} from "../matchStatuses/statuses";

export default function MatchBoard() {
  return (
    <>
      <Title title="Propositions" />
      <Toolbar>
        <Button
          variant="contained"
          component={RouterLink}
          to="/matches/create"
          startIcon={<Add />}
          size="small"
        >
          Démarrer
        </Button>
      </Toolbar>
      <Matrice
        resource="matches"
        basePath="/matches"
        columnReference="matchStatuses"
        columnTarget="status"
        columnIds={matchStatuses}
        rowReference="profiles"
        rowTarget="profile"
        rowLabel={(record) => `${record.firstname} ${record.lastname}`}
        columnLabel={(record) => record.name}
        CellComponent={MatchChip}
      />
    </>
  );
}

const matchStatuses = [
  PROPOSED,
  ON_REVIEW,
  VALIDATED,
  CLOSED_BY_CLIENT,
  CLOSED_BY_US,
];

function MatchChip({ record, draggable, onDragStart, onDragEnd }) {
  return (
    <Chip
      label={record.client}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      sx={{
        cursor: "grab",
      }}
    />
  );
}
