import { Edit } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import ProfileStatusForm from "./ProfileStatusForm";

export default function ProfileStatusEdit(props) {
  return (
    <Edit {...props}>
      <ProfileStatusForm toolbar={<FormToolbar />} />
    </Edit>
  );
}
