import { ViewKanban, ViewList } from "@mui/icons-material";
import { ButtonGroup, Stack, Toolbar } from "@mui/material";
import React, { useState } from "react";

import ViewButton from "../../components/ViewButton";
import RecruitmentBoard from "./RecruitmentBoard";
import RecruitmentList from "./RecruitmentList";

const kanban = "kanban";
const list = "list";
const views = {
  [kanban]: <RecruitmentBoard />,
  [list]: <RecruitmentList />,
};

export default function RecruitmentAll() {
  const [view, setView] = useState(kanban);
  const onChangeView = (name) => () => {
    setView(name);
  };
  return (
    <Stack spacing={2}>
      <Toolbar>
        <ButtonGroup>
          <ViewButton
            onClick={onChangeView(kanban)}
            startIcon={<ViewKanban />}
            isActive={view === kanban}
          >
            Vue kanban
          </ViewButton>
          <ViewButton
            onClick={onChangeView(list)}
            startIcon={<ViewList />}
            isActive={view === list}
          >
            Vue liste
          </ViewButton>
        </ButtonGroup>
      </Toolbar>
      {views[view]}
    </Stack>
  );
}
