import { ROLE_ADMIN, ROLE_MEMBER, ROLE_SUPER_ADMIN } from "./roles";

const roleChoices = [
  {
    id: ROLE_SUPER_ADMIN,
    name: "Super Admin",
    restrictedTo: [ROLE_SUPER_ADMIN],
  },
  { id: ROLE_ADMIN, name: "Admin" },
  { id: ROLE_MEMBER, name: "Team member" },
];

export default roleChoices;
