import { Edit } from "react-admin";

import NeedsForm from "./NeedForm";

export default function NeedEdit(props) {
  return (
    <Edit {...props} redirect="list">
      <NeedsForm />
    </Edit>
  );
}
