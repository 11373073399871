import get from "lodash/get";

import search from "./search";

function parse(value) {
  return value?.toLocaleLowerCase?.() || value?.toLocaleString?.() || value;
}

function match(asserts = [], expects = []) {
  return [expects]
    .flat()
    .some((expect) =>
      [asserts].flat().some((assert) => parse(assert) === parse(expect)),
    );
}

export default function list(data = [], params) {
  const { q, ...filters } = params?.filter || {};
  if (q) {
    data = [...data.filter((doc) => search(doc, q))];
  }
  Object.keys(filters).forEach((field) => {
    data = [
      ...data.filter((doc) => match(get(doc, field), get(filters, field))),
    ];
  });

  const total = data.length;
  if (params?.pagination) {
    const page = params?.pagination?.page || 1;
    const perPage = params?.pagination?.perPage || total;
    const start = (page - 1) * perPage;
    const end = page * perPage;

    data = [...data.slice(start, end)];
  }

  return { data, total };
}
