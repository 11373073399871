import {
  AutocompleteArrayInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import DatePickerInput from "../../components/DatePickerInput";
import FormToolbar from "../../components/FormToolbar";
import formatSeniorityLevelText from "../../resources/seniorityLevels/formatSeniorityLevelText";
import status from "./status";

export default function NeedForm(props) {
  return (
    <SimpleForm toolbar={<FormToolbar />} {...props}>
      <SelectInput label="Statut" source="status" choices={status} fullWidth />
      <DatePickerInput
        label="Date d'échéance"
        source="dueDate"
        defaultValue={Date.now()}
        fullWidth
      />
      <TextInput source="client" fullWidth />
      <TextInput label="Titre" source="name" fullWidth />
      <NumberInput label="TJM (€)" source="dailyRate" min={0} fullWidth />
      <ReferenceArrayInput
        source="seniorities"
        reference="seniorityLevels"
        sort={{
          field: "experience.min",
          order: "ASC",
        }}
      >
        <AutocompleteArrayInput
          label="Séniorité"
          optionText={formatSeniorityLevelText}
          fullWidth
        />
      </ReferenceArrayInput>
      <BooleanInput label="Avec responsabilité" source="withResponsability" />
      <ReferenceArrayInput
        source="skills"
        reference="skills"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteArrayInput
          label="Compétences"
          optionText="name"
          fullWidth
        />
      </ReferenceArrayInput>
      <TextInput
        source="description"
        fullWidth
        multiline
        minRows={3}
        defaultValue=""
      />
    </SimpleForm>
  );
}
