import { Face2 } from "@mui/icons-material";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/roles";
import ProfileStatusCreate from "./ProfileStatusCreate";
import ProfileStatusEdit from "./ProfileStatusEdit";
import ProfileStatusList from "./ProfileStatusList";

const profileStatuses = {
  create: ProfileStatusCreate,
  edit: ProfileStatusEdit,
  list: ProfileStatusList,
  restrictedTo: [ROLE_SUPER_ADMIN, ROLE_ADMIN],
  options: { label: "Statuts de profil" },
  icon: Face2,
  name: "statuses",
};

export default profileStatuses;
