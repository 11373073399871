import { Create } from "react-admin";

import SkillForm from "./SkillForm";

export default function SkillCreate(props) {
  return (
    <Create {...props} redirect="create">
      <SkillForm />
    </Create>
  );
}
