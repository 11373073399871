import { format } from "phone-fns";
import { RichTextInput } from "ra-input-rich-text";
import {
  ArrayInput,
  AutocompleteArrayInput,
  Edit,
  email,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from "react-admin";

import { contractTypes } from "../../components/ContractTypeField";
import DatePickerInput from "../../components/DatePickerInput";
import FormToolbar from "../../components/FormToolbar";
import FullNameField from "../../components/FullNameField";
import formatSeniorityLevelText from "../../resources/seniorityLevels/formatSeniorityLevelText";

const Null = () => null;

const contractTypeChoices = Object.keys(contractTypes).map((id) => ({
  id,
  name: contractTypes[id],
}));

export default function ProfileEdit(props) {
  return (
    <Edit
      title={<FullNameField component="span" variant="h6" />}
      {...props}
      redirect="show"
    >
      <TabbedForm toolbar={<FormToolbar />}>
        <FormTab label="Profil">
          <TextInput
            label="Prénom"
            source="firstname"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Nom"
            source="lastname"
            validate={[required()]}
            fullWidth
          />
          <ReferenceInput source="status" reference="statuses">
            <SelectInput
              label="Statut"
              optionText="name"
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          <TextInput label="Titre" source="jobtitle" fullWidth />
          <ReferenceInput source="seniority" reference="seniorityLevels">
            <SelectInput
              label="Séniorité"
              optionText={formatSeniorityLevelText}
              fullWidth
            />
          </ReferenceInput>
          <ReferenceArrayInput
            source="skills"
            reference="skills"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteArrayInput
              label="Compétences"
              optionText="name"
              fullWidth
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            source="badges"
            reference="badges"
            label="Badges"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Contact">
          <TextInput
            source="linkedInUrl"
            type="url"
            label="Compte LinkedIn"
            fullWidth
          />
          <TextInput
            source="gitLabUrl"
            type="url"
            label="Compte GitLab"
            fullWidth
          />
          <TextInput
            source="gitHubUrl"
            type="url"
            label="Compte GitHub"
            fullWidth
          />
          <TextInput
            label="Numéro de téléphone"
            source="phoneNumber"
            type="tel"
            parse={format("N".repeat(10))}
            validate={[
              regex(
                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                "Must be a valid phone number",
              ),
            ]}
            fullWidth
          />
          <TextInput
            label="Adresse email"
            source="emailAdress"
            type="email"
            validate={[email()]}
            fullWidth
          />
        </FormTab>
        <FormTab label="Introduction">
          <TextInput label="" source="intro" fullWidth multiline minRows={3} />
        </FormTab>
        <FormTab label="Expériences">
          <ArrayInput label="" source="experiences">
            <SimpleFormIterator fullWidth reOrderButtons={<Null />}>
              <TextInput
                label="Titre"
                source="title"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="Entreprise"
                source="company"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="Localisation"
                source="location"
                fullWidth
                defaultValue=""
              />
              <DatePickerInput
                label="Début"
                source="startedAt"
                validate={[required()]}
                fullWidth
              />
              <DatePickerInput label="Fin" source="endedAt" fullWidth />
              <NumberInput
                label="Taille d'équipe"
                source="teamSize"
                min={1}
                step={1}
                initialValue={1}
                fullWidth
              />
              <NumberInput
                label="TJM (€)"
                source="dailyRate"
                min={0}
                fullWidth
              />
              <RichTextInput
                label="Réalisations"
                source="description"
                fullWidth
              />
              <ReferenceArrayInput
                source="skills"
                reference="skills"
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteArrayInput
                  label="Compétences"
                  optionText="name"
                  fullWidth
                />
              </ReferenceArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Formations">
          <ArrayInput label="" source="formations">
            <SimpleFormIterator fullWidth reOrderButtons={<Null />}>
              <TextInput
                label="Nom"
                source="name"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="Établissement"
                source="school"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="Localisation"
                source="location"
                fullWidth
                defaultValue=""
              />
              <DatePickerInput
                label="Début"
                source="startedAt"
                validate={[required()]}
              />
              <DatePickerInput label="Fin" source="endedAt" />
              <RichTextInput
                label="Réalisations"
                source="description"
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Contrat">
          <AutocompleteArrayInput
            label="Types de contrat"
            source="contractTypes"
            choices={contractTypeChoices}
            fullWidth
          />
          <NumberInput label="TJM (€)" source="dailyRate" min={0} fullWidth />
        </FormTab>
        <FormTab label="Commentaires">
          <ArrayInput label="" source="comments">
            <SimpleFormIterator fullWidth>
              <TextInput
                label=""
                source="content"
                fullWidth
                multiline
                minRows={2}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
