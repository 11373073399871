import { alpha, Button } from "@mui/material";
import React from "react";

export default function ViewButton({ isActive = false, children, ...props }) {
  return (
    <Button
      size="small"
      sx={{
        bgcolor: (theme) =>
          isActive ? alpha(theme.palette.primary.main, 0.1) : null,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
