import { Chip, Stack } from "@mui/material";
import get from "lodash/get";
import { useRecordContext } from "react-admin";

export const contractTypes = {
  cdi: "CDI",
  freelance: "Freelance",
  esn: "ESN",
};

export default function ContractTypesField({ source }) {
  const record = useRecordContext();
  const ids = get(record, source);
  if (!ids?.length > 0) return null;
  return (
    <Stack direction="row" alignItems="center">
      {ids.map((id) => (
        <Chip key={id} label={contractTypes[id]} size="small" sx={{ m: 0.5 }} />
      ))}
    </Stack>
  );
}
