import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import HomonymHelpertext from "../../components/HomonymHelpertext";
import { TO_CONTACT } from "../../resources/profileStatuses/statuses";

export default function ProfileCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm defaultValues={{ status: TO_CONTACT }}>
        <TextInput
          label="Prénom"
          source="firstname"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Nom"
          source="lastname"
          validate={[required()]}
          fullWidth
          helperText
        />
        <ReferenceInput label="Statut" source="status" reference="statuses">
          <SelectInput
            resettable
            optionText="name"
            fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
        <HomonymHelpertext />
      </SimpleForm>
    </Create>
  );
}
