import {
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useResourceContext,
} from "react-admin";

import types from "./types";

export default function SkillForm(props) {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const isUniq = async (value) => {
    try {
      const { total } = await dataProvider.getList(resource, {
        filter: { name: value.name },
      });
      return total === 0
        ? {}
        : { name: "Une compétence avec le même nom existe déjà." };
    } catch (error) {
      return {};
    }
  };

  return (
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton redirect="list" />
        </Toolbar>
      }
      validate={isUniq}
      {...props}
    >
      <TextInput source="name" validate={[required()]} fullWidth />
      <SelectInput
        source="type"
        choices={types}
        validate={[required()]}
        fullWidth
      />
    </SimpleForm>
  );
}
