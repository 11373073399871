import { Create } from "react-admin";

import JobForm from "./JobForm";

export default function JobCreate(props) {
  return (
    <Create {...props} redirect="list">
      <JobForm />
    </Create>
  );
}
