import { Edit, NumberInput, SimpleForm, TextInput } from "react-admin";

import FormToolbar from "../../components/FormToolbar";
import NextStatusInput from "../../components/NextStatusInput";

export default function RecruitmentStatusEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<FormToolbar />}>
        <TextInput label="Nom" source="name" fullWidth />
        <NextStatusInput
          label="Prochains statuts"
          source="next"
          reference="recruitmentStatuses"
          fullWidth
        />
        <NumberInput
          label="Durée (jours)"
          source="duration"
          min={1}
          step={1}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
}
