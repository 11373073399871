import { Create } from "react-admin";

import NeedsForm from "./NeedForm";
import { defaultStatus } from "./status";

export default function NeedCreate(props) {
  return (
    <Create {...props} redirect="list">
      <NeedsForm defaultValues={{ status: defaultStatus }} />
    </Create>
  );
}
