import { cloneElement, useCallback, useState } from "react";

export default function Draggable({ children, onDragStart: onDrag }) {
  const [dragging, setDragging] = useState(false);
  const onDragStart = useCallback(
    (event) => {
      setDragging(true);
      onDrag(event);
    },
    [onDrag],
  );
  const onDragEnd = useCallback(() => {
    setDragging(false);
  }, []);
  return cloneElement(children, {
    variant: dragging ? "outlined" : null,
    draggable: true,
    onDragStart,
    onDragEnd,
  });
}
