import { Create, SimpleForm, TextInput } from "react-admin";

export default function RecruitmentStatusCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" defaultValue="" fullWidth />
      </SimpleForm>
    </Create>
  );
}
