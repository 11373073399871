import { SimpleForm, TextInput } from "react-admin";

import NextStatusInput from "../../components/NextStatusInput";

export default function ProfileStatusForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput label="Nom" source="name" defaultValue="" fullWidth />
      <NextStatusInput
        label="Prochains statuts"
        source="next"
        reference="statuses"
        fullWidth
      />
    </SimpleForm>
  );
}
